<template>
  <section class="headSection main-filter">
    <form
      @submit.prevent
      class="w-100 d-flex justify-content-between align-items-center flex-wrap"
    >
      <div class="frm-controls w-100 p-0">
        <div class="col-md-5" v-if="withSearch">
          <div class="input_wrapper top_label">
            <label for="searchInput" class="form-label">{{
              $t("table.search")
            }}</label>
            <v-text-field
              id="searchInput"
              prepend-icon="mdi-magnify"
              v-model="frmData.searchInput"
              @input="setKeyWord"
            ></v-text-field>
          </div>
        </div>
        <div class="col-md-3" v-if="orderId">
          <div class="input_wrapper top_label">
            <label for="searchInput" class="form-label">{{
              $t("table.order_id")
            }}</label>
            <v-text-field
              id="searchInput"
              prepend-icon="mdi-magnify"
              v-model="frmData.order_id"
              @input="getResult"
            ></v-text-field>
          </div>
        </div>
        <div class="col-md-3" v-if="customerNumber">
          <div class="input_wrapper top_label">
            <label for="searchInput" class="form-label">{{
              $t("table.customer_number")
            }}</label>
            <v-text-field
              id="searchInput"
              prepend-icon="mdi-magnify"
              v-model="frmData.customer_number"
              @input="setKeyWord($event, 'customer_number')"
            ></v-text-field>
          </div>
        </div>
        <div class="col-md-3" v-if="branchNumber">
          <div class="input_wrapper top_label">
            <label for="searchInput" class="form-label">{{
              $t("table.branch_number")
            }}</label>
            <v-text-field
              id="searchInput"
              prepend-icon="mdi-magnify"
              v-model="frmData.branch_number"
              @input="setKeyWord($event, 'branch_number')"
            ></v-text-field>
          </div>
        </div>
        <div class="col-md-3" v-if="branchName">
          <div class="input_wrapper top_label">
            <label for="searchInput" class="form-label">{{
              $t("table.branch_name")
            }}</label>
            <v-text-field
              id="searchInput"
              prepend-icon="mdi-magnify"
              v-model="frmData.branch_name"
              @input="setKeyWord($event, 'branch_name')"
            ></v-text-field>
          </div>
        </div>
        <div
          class="categories col-sm-6 col-md-3 col-xxl-3"
          v-if="delievery_type"
        >
          <div class="select-wrapper">
            <div class="input_wrapper top_label">
              <label for="" class="form-label">{{
                $t("delievery_type")
              }}</label>

              <multiselect
                v-model="frmData.delievery_type"
                :options="delievery_types"
                @input="setKeyType($event, 'is_pickup')"
                label="name"
                track-by="value"
                :show-labels="false"
                placeholder=""
                :searchable="true"
              ></multiselect>
            </div>
          </div>
        </div>
        <div class="col-md-4" v-if="code">
          <div class="input_wrapper top_label">
            <label for="searchInput" class="form-label">{{
              $t("titles.code")
            }}</label>
            <v-text-field
              id="searchInput"
              prepend-icon="mdi-magnify"
              v-model="frmData.code"
              @input="setCode"
            ></v-text-field>
          </div>
        </div>

        <div class="categories col-sm-6 col-md-4 col-xxl-3" v-if="type">
          <div class="select-wrapper">
            <div class="input_wrapper top_label">
              <label for="" class="form-label">{{
                $t("forms.labels.type")
              }}</label>
              <multiselect
                v-model="frmData.type"
                :options="leaguesTypes"
                label="name"
                track-by="value"
                :show-labels="false"
                placeholder=""
                :searchable="true"
                @input="getResult"
              ></multiselect>
            </div>
          </div>
        </div>

        <div class="date col-sm-6 col-md-4 col-xxl-3" v-if="dateStart">
          <div class="select-wrapper">
            <div class="input_wrapper top_label">
              <label for="" class="form-label">
                {{
                  dateStart
                    ? $t("forms.labels.start_date")
                    : $t("forms.labels.date")
                }}
              </label>
              <v-menu
                ref="dateStartModal"
                v-model="dateStartModal"
                :close-on-content-click="false"
                :return-value.sync="frmData.dateStart"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <!-- multiple
                  chips
                  small-chips -->
                  <v-combobox
                    v-model="frmData.dateStart"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-combobox>
                </template>
                <!-- multiple -->
                <v-date-picker
                  @input="
                    getResult();
                    dateStartModal = false;
                    $refs.dateStartModal.save(frmData.dateStart);
                  "
                  v-model="frmData.dateStart"
                  no-title
                  scrollable
                >
                  <v-btn
                    text
                    color="primary"
                    @click="
                      frmData.dateStart = '';
                      $refs.dateStartModal.save(frmData.dateStart);
                      dateStartModal = false;
                      getResult();
                    "
                  >
                    الغاء
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </div>
          </div>
        </div>
        <div class="date col-sm-6 col-md-4 col-xxl-3" v-if="dateEnd">
          <div class="select-wrapper">
            <div class="input_wrapper top_label">
              <label for="" class="form-label">
                {{ $t("forms.labels.end_date") }}
              </label>
              <v-menu
                ref="dateEndModal"
                v-model="dateEndModal"
                :close-on-content-click="false"
                :return-value.sync="frmData.dateEnd"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <!-- multiple
                  small-chips -->
                  <v-combobox
                    v-model="frmData.dateEnd"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-combobox>
                </template>
                <!-- multiple -->
                <v-date-picker
                  @input="
                    getResult();
                    dateEndModal = false;
                    $refs.dateEndModal.save(frmData.dateEnd);
                  "
                  v-model="frmData.dateEnd"
                  no-title
                  scrollable
                >
                  <v-btn
                    text
                    color="primary"
                    @click="
                      frmData.dateEnd = '';
                      $refs.dateEndModal.save(frmData.dateEnd);
                      dateEndModal = false;
                      getResult();
                    "
                  >
                    الغاء
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </div>
          </div>
        </div>
        <!-- <div class="categories col-sm-6 col-md-4 col-xxl-3" v-if="status">
          <div class="select-wrapper">
            <div class="input_wrapper top_label">
              <label for="" class="form-label">
                الحالة
              </label>
              <multiselect
                v-model="frmData.status"
                :options="orderStatusItems"
                label="label"
                track-by="name"
                :show-labels="false"
                placeholder=""
                :searchable="true"
                @input="getResult"
              ></multiselect>
            </div>
          </div>
        </div> -->
        <div
          class="categories col-sm-6 col-md-4 col-xxl-3"
          v-if="users && clients"
        >
          <div class="select-wrapper">
            <div class="input_wrapper top_label auto_complete">
              <label for="" class="form-label"> المستخدمين </label>

              <v-autocomplete
                v-model="frmData.user"
                :search-input.sync="userKey.input"
                :items="clients"
                item-text="fullname"
                item-value="id"
                placeholder="المستخدمين"
                @input="getResult()"
                :hide-no-data="!clients"
                dense
              ></v-autocomplete>
            </div>
          </div>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import _ from "lodash";
export default {
  props: {
    type: {
      type: Boolean,
      default: false,
    },

    dateStart: {
      type: Boolean,
      default: false,
    },
    dateEnd: {
      type: Boolean,
      default: false,
    },

    withSearch: {
      type: Boolean,
      default: true,
    },
    orderId: {
      type: Boolean,
      default: false,
    },
    customerNumber: {
      type: Boolean,
      default: false,
    },

    branchNumber: {
      type: Boolean,
      default: false,
    },

    branchName: {
      type: Boolean,
      default: false,
    },

    users: {
      type: Boolean,
      default: false,
    },

    code: {
      type: Boolean,
      default: false,
    },
    delievery_type: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      dateStartModal: false,
      dateEndModal: false,
      frmData: {
        searchInput: null,
        order_id: null,
        customer_number: this.$route.query.customer_number || null,
        branch_number: this.$route.query.branch_number || null,
        branch_name: this.$route.query.branch_name || null,
        type: null,
        dateStart: null,
        dateEnd: null,
        user: null,
        delievery_type: null,
        code: null,
      },
      userKey: {
        input: "",
        keyword: "",
      },
      delievery_types: [
        {
          name: this.$t("is_pick_up_from_store"),
          value: 1,
        },
        {
          name: this.$t("delievery"),
          value: 0,
        },
      ],
    };
  },
  watch: {
    ["userKey.input"](newVal) {
      if (newVal) {
        this.userKey.keyword = newVal;
        this.get_filterd_users();
      }
    },
    ["frmData.dateStart"](newVal) {
      if (newVal) {
        this.getResult();
      }
    },
    ["frmData.dateEnd"](newVal) {
      if (newVal) {
        this.getResult();
      }
    },

    ["$route"](newVal) {
      if (newVal.query.keyword) {
        this.frmData.searchInput = newVal.query.keyword;
      }
      if (newVal.query.code) {
        this.frmData.code = newVal.query.code;
      }

      this.getResult();
    },
  },
  mounted() {
    if (this.$route.query.keyword) {
      this.frmData.searchInput = this.$route.query.keyword;
      // this.getResult()
    }

    if (this.$route.query.is_pickup == 0 || this.$route.query.is_pickup == 1) {
      this.frmData.delievery_type = this.delievery_types.find(
        (el) => el.value == this.$route.query.is_pickup
      );

      console.log(this.frmData.delievery_type);
      // this.getResult()
    }
    if (this.$route.query.code) {
      this.frmData.code = this.$route.query.code;
      // this.getResult()
    }
  },
  methods: {
    getResult() {
      this.$emit("getResult", this.frmData);
    },

    setKeyWord(e, name) {
      this.$router
        .push({
          query: Object.assign({}, this.$route.query, {
            [name ? name : "keyword"]: e,
            page: 1,
          }),
        })
        .catch(() => {});
      // this.$router.push({ query: { keyword: e, page: 1 } })
      // this.$router.replace(`?keyword=${e}`).catch(() => {})
    },
    setKeyType(e) {
      this.$router
        .push({
          query: Object.assign({}, this.$route.query, {
            is_pickup: e?.value || e?.value == 0 ? e.value : "",
            page: 1,
          }),
        })
        .catch(() => {});

      // this.$router.push({ query: { keyword: e, page: 1 } })
      // this.$router.replace(`?keyword=${e}`).catch(() => {})
    },
    setCode(e) {
      this.$router
        .push({
          query: Object.assign({}, this.$route.query, {
            code: e,
            page: 1,
          }),
        })
        .catch(() => {});
      // this.$router.push({ query: { code: e, page: 1 } })
      // this.$router.replace(`?code=${e}`).catch(() => {})
    },
  },
};
</script>

<style lang="scss">
.main-filter {
  .frm-controls {
    display: flex;
    flex-wrap: wrap;

    .form-control {
      // background: var(--mainBgColor) !important;
      // color: var(--blackColor) !important;
      border: none;
      height: 45px;
    }

    .v-text-field {
      padding-top: 0;
      margin-top: 0;
      // background: var(--mainBgColor) !important;
      border-radius: 0.375rem;
      align-items: center;
    }
    .v-autocomplete.v-input > .v-input__control > .v-input__slot::before {
      display: none;
    }
    .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
      display: none;
    }
  }
  form {
    .form-control {
      width: 100% !important;
    }
    .form-action {
      width: 100% !important;
    }
    min-height: 55px;
  }

  .search-icon {
    color: var(--blackColor) !important;
  }
  .v-text-field__details {
    display: none;
  }

  .v-input__slot {
    margin: 0;
    height: 45px !important;
    box-shadow: none !important;
  }
  .v-text-field.v-text-field--solo .v-input__control {
    min-height: auto !important;
  }
  .row {
    margin: 0;
    > div {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }

  a.btn {
    background: var(--mainColor-2) !important;
    color: #fff !important;
    height: 45px;
    font-size: 16px;
    line-height: 30px;
  }
  .multiselect__tags {
    height: 45px;
    text-align: start;

    border: none;
    .multiselect__tags-wrap {
      display: flex;
      overflow-x: auto;
    }

    .multiselect__element {
      text-align: start !important;
    }
    .multiselect__single {
      background: var(--mainBgColor);
    }
  }
  .multiselect__content-wrapper {
    background: var(--mainBgColor) !important;
    border: none;
  }

  .v-input input {
    padding: 6px 12px;
    & ~ label {
      right: 6px !important;
    }
  }

  .v-text-field
    > .v-input__control
    > .v-input__slot
    > .v-text-field__slot
    label {
    right: 6px !important;
  }

  .input_wrapper {
    margin-block: 10px !important;
  }
}

.ltr {
  .main-filter {
    .frm-controls {
      .v-text-field {
        flex-direction: row-reverse;
      }
    }
  }
}
</style>
