<template>
  <div class="create_wrapper product">
    <!-- Start Breadcrumb -->
    <Breadcrumb :items="items" />
    <!-- End Breadcrumb -->

    <div class="custom_card">
      <div class="card-header">
        <h4 class="card-title">{{ $t("edit") }}</h4>
      </div>
      <main-loader v-if="btnIsLoading"></main-loader>
      <!-- ==== Start Form ==== -->
      <form @submit.prevent="validateCreateForm" v-else>
        <div class="container">
          <div class="row justify-content-between product">
            <div class="col-lg-6 py-0" v-if="false">
              <v-switch
                v-model="selectAddType"
                :label="$t('titles.productImages')"
              ></v-switch>
            </div>
            <div class="col-12 py-0"></div>
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.ar.name"
                  @keypress="isArabic($event)"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.name_ar") }}
                </label>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  v-model.trim="data.en.name"
                  @keypress="isEnglish($event)"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.name_en") }}
                </label>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <label class="form-label">
                  {{ $t("forms.labels.category") }}
                </label>
                <treeselect
                  dir="rtl"
                  :multiple="true"
                  @input="getRootCategory($event)"
                  placeholder=""
                  :options="categories"
                  :disable-branch-nodes="true"
                  :show-count="true"
                  :flat="true"
                  v-model="data.selected_ids"
                />
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="number"
                  class="form-control"
                  v-model.trim="data.limit_order_num"
                />

                <label class="form-label"
                  >{{ $t("forms.labels.limit_order_num") }}
                </label>
              </div>
            </div>

            <div class="col-lg-4 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.code"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.product_code") }}
                </label>
              </div>
            </div>
            <div class="col-lg-4 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="number"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.ordering"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.ordering") }}
                </label>
              </div>
            </div>
            <div class="col-lg-4 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="number"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.price"
                  step="any"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.price") }}
                </label>
              </div>
            </div>
            <div class="col-lg-4 py-0">
              <div class="input_wrapper top_label">
                <input
                  @keypress="preventMinus"
                  type="number"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.points"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.points") }}
                </label>
              </div>
            </div>
            <div class="col-lg-4 py-0" v-if="brands">
              <div class="input_wrapper top_label">
                <label class="form-label">{{
                  $t("forms.labels.productType")
                }}</label>
                <multiselect
                  v-model="data.type"
                  :options="types"
                  label="name"
                  track-by="id"
                  placeholder=" "
                  :searchable="true"
                  :allow-empty="true"
                  :show-labels="false"
                ></multiselect>
              </div>
            </div>
            <div class="col-lg-4 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="number"
                  class="form-control"
                  v-model.trim="data.percentage"
                  step="0.00000000000001"
                />

                <label class="form-label"
                  >{{ $t("forms.labels.discountPercentage") }}
                </label>
              </div>
            </div>

            <div class="col-lg-6 py-0" v-if="brands">
              <div class="input_wrapper top_label">
                <label class="form-label">{{ $t("titles.brands") }}</label>
                <multiselect
                  v-model="data.brand"
                  :options="brands"
                  label="name"
                  track-by="id"
                  placeholder=" "
                  :searchable="true"
                  :allow-empty="false"
                  :show-labels="false"
                ></multiselect>
              </div>
            </div>
            <template v-if="!data.is_check_all_branch">
              <div
                class="row align-items-center"
                v-for="(item, index) in data.branches"
                :key="item.uuid"
                :class="`branches${index}`"
              >
                <div class="col-lg-5 py-0">
                  <!-- Start:: Select Unput -->
                  <div class="input_wrapper top_label">
                    <label class="form-label">
                      {{ $t("forms.labels.branch") }}
                    </label>
                    <multiselect
                      v-model="item.branch"
                      :options="branches"
                      label="name"
                      track-by="id"
                      placeholder=" "
                      @input="getCurrentBranches($event)"
                      :searchable="true"
                      :allow-empty="false"
                      :show-labels="false"
                    >
                    </multiselect>
                  </div>
                  <!-- End:: Select Unput -->
                </div>
                <!-- End:: Expeses category -->
                <!-- Start:: Expense Image -->
                <div class="col-lg-5 py-0">
                  <div class="input_wrapper top_label">
                    <input
                      type="number"
                      class="form-control"
                      @keypress="preventMinus($event)"
                      v-model.trim="item.quantity"
                    />

                    <label class="form-label"
                      >{{ $t("forms.labels.quantity") }}
                    </label>
                  </div>
                </div>
                <!-- End:: Expense Image -->
                <div class="col-lg-2 py-0">
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    color="primary"
                    @click="addBranch()"
                    v-if="index == data.branches.length - 1"
                  >
                    <v-icon dark> mdi-plus </v-icon>
                  </v-btn>
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    color="primary"
                    @click="removeBranch(index)"
                    v-if="data.branches.length > 1"
                  >
                    <v-icon dark> mdi-minus </v-icon>
                  </v-btn>
                </div>
              </div>
            </template>
            <div class="col-lg-6 py-0" v-else>
              <div class="input_wrapper top_label">
                <input
                  type="number"
                  class="form-control"
                  @keypress="preventMinus($event)"
                  v-model.trim="data.branches_quantity"
                />

                <label class="form-label">{{
                  $t("forms.labels.quantity")
                }}</label>
              </div>
            </div>
            <div class="col-12 py-0">
              <v-checkbox
                :label="$t('forms.labels.is_check_all_branch')"
                v-model="data.is_check_all_branch"
                color="#FF4300"
              ></v-checkbox>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <ckeditor v-model="data.ar.desc" />

                <label for="textarea_1" class="form-label">
                  {{ $t("forms.labels.desc_ar") }}
                </label>
                <!-- <textarea
                  class="form-control"
                  rows="5"
                  v-model="data.ar.desc"
                  @keypress="isArabic($event)"
                ></textarea> -->
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <ckeditor v-model="data.en.desc" />
                <label for="textarea_1" class="form-label">
                  {{ $t("forms.labels.desc_en") }}
                </label>
                <!-- <textarea
                  class="form-control"
                  rows="5"
                  v-model="data.en.desc"
                  @keypress="isEnglish($event)"
                ></textarea> -->
              </div>
            </div>

            <!-- Product images -->
            <div class="col-12">
              <div class="row productDetails mb-2">
                <h4>{{ $t("forms.labels.productImages") }}</h4>
                <div
                  class="col-lg-6"
                  v-for="(image, index) in data.images"
                  :key="image.uuid"
                >
                  <div class="row">
                    <div class="col-11">
                      <uplode-image
                        @inputChanged="uplodeProductImage($event, index)"
                        :data_src="image.image"
                        :placeHolder="$t('forms.labels.image')"
                      ></uplode-image>
                    </div>

                    <div
                      class="col-1 py-0 d-flex justify-center align-center flex-column"
                    >
                      <span
                        v-if="index == data.images.length - 1"
                        class="append mx-1"
                        @click="appendImageRow"
                      >
                        <i class="fas fa-plus-circle"></i>
                      </span>

                      <span
                        class="append mx-1"
                        @click="deleteImageRow(index, image.id)"
                      >
                        <i class="fas fa-minus-circle"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Product images -->

            <!-- Start:: Is Active -->
            <div class="col-lg-4 py-0">
              <v-checkbox
                :label="$t('forms.labels.is_active')"
                v-model="data.is_active"
                color="#FF4300"
              ></v-checkbox>
            </div>
            <!-- End:: Is Active -->
            <!-- Start:: Is prescription_requirement -->
            <div class="col-lg-4 py-0">
              <v-checkbox
                :label="$t('forms.labels.prescription_requirement')"
                v-model="data.prescription_requirement"
                color="#FF4300"
              ></v-checkbox>
            </div>
            <!-- End:: Is prescription_requirement -->
            <!-- Start:: Is prescription_requirement -->
            <div class="col-lg-4 py-0">
              <v-checkbox
                :label="$t('forms.labels.apply_vat')"
                v-model="data.apply_vat"
                color="#FF4300"
              ></v-checkbox>
            </div>
            <!-- End:: Is prescription_requirement -->
            <!-- Start:: Is Replace -->
            <!-- <div class="col-lg-4 py-0">
              <v-checkbox
                :label="$t('forms.labels.is_replace')"
                v-model="data.is_replace"
                color="#FF4300"
              ></v-checkbox>
            </div> -->
            <!-- End:: Is Replace -->
          </div>
        </div>

        <div class="//buttons_wrapper">
          <button class="button_style_1" :disabled="btnIsLoading">
            {{ $t("forms.submit") }}
            <span class="btn_loader" v-if="btnIsLoading"></span>
          </button>
        </div>
      </form>
      <!-- ==== End Form ==== -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Update",

  props: ["id"],

  data() {
    return {
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.products.title"),
          disabled: false,
          href: "/products/show-all",
        },
        {
          text: this.$t("breadcrumb.products.edit"),
          disabled: true,
          href: "",
        },
      ],

      // ========== Loading
      btnIsLoading: false,

      // ========== create_data

      data: {
        ar: {
          name: null,
          desc: null,
        },
        en: {
          name: null,
          desc: null,
        },
        category: null,
        code: null,
        type: null,
        brand: null,
        percentage: null,
        is_active: false,
        apply_vat: false,
        // is_replace: false,
        prescription_requirement: false,
        limit_order_num: null,
        product_details: [
          {
            quantity: null,
            price: null,
            color: null,
            size: null,
            features: [
              {
                feature: null,
                value: null,
                values: [],
              },
            ],
            media: [
              {
                image: null,
              },
            ],
          },
        ],
        images: [
          {
            uuid: (((1 + Math.random()) * 0x10000) | 0)
              .toString(16)
              .substring(1),
            image: null,
          },
        ],
        branches: [
          {
            uuid: (((1 + Math.random()) * 0x10000) | 0)
              .toString(16)
              .substring(1),
            branch: null,
            quantity: null,
          },
        ],

        selected_ids: [],
        rootsCategories: [],
        size_guide: null,
        is_active: true,
        ordering: null,
        is_check_all_branch: false,
        branches_quantity: null,
      },
      selectAddType: true,
      rootCategory: null,
      categories: [],
      price: null,
      points: null,

      colors: [],
      sizes: [],
      features: [],
      brands: [],
      branches: [],
      allBranches: [],
      types: [
        {
          name: this.$t("titles.topDeals"),
          id: "top_deals",
        },
        {
          name: this.$t("titles.sales"),
          id: "sales",
        },
        {
          name: this.$t("titles.news"),
          id: "news",
        },
      ],
    };
  },

  computed: {
    product_details() {
      return this.data.product_details;
    },
  },

  methods: {
    // Validate Data
    validateCreateForm() {
      this.btnIsLoading = true;

      if (!this.data.ar.name) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.name_ar"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.en.name) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.name_en"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.en.desc) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.content_ar"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.ar.desc) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.content_en"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (this.data.selected_ids.length < 1) {
        this.$iziToast.error({
          timeout: 2000,
          // message: this.$t("forms.validation.content_ar"),
          message: this.$t("forms.validation.category"),
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.price || this.data.price < 0) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.price"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      }
      // else if (!this.data.type) {
      //   this.$iziToast.error({
      //     timeout: 2000,
      //     message: this.$t("forms.validation.productType"),
      //     position: "bottomRight",
      //   });
      //   this.btnIsLoading = false;
      //   return;
      // }
      let validate = true;
      if (this.data.is_check_all_branch) {
        if (!this.data.branches_quantity) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.quantity"),
          });
          this.btnIsLoading = false;
          validate = false;
        }
      } else {
        this.data.branches.map((el, index) => {
          if (!validate) return;
          if (!el.branch) {
            this.$iziToast.error({
              timeout: 2000,
              message:
                this.$t("forms.validation.branch") +
                " " +
                "( " +
                (index + 1) +
                " )",
            });
            this.btnIsLoading = false;
            validate = false;
          } else if (el.quantity == null) {
            this.$iziToast.error({
              timeout: 2000,
              message:
                this.$t("forms.validation.quantity") +
                " " +
                "( " +
                (index + 1) +
                " )",
            });
            this.btnIsLoading = false;
            validate = false;
          }
        });
      }
      if (!validate) return;

      let validate1 = true;
      // this.data.images.map((el, index) => {
      //   if (!validate1) return;
      //   if (!el.image) {
      //     this.$iziToast.error({
      //       timeout: 2000,
      //       message:
      //         this.$t("forms.validation.image") +
      //         " " +
      //         "( " +
      //         (index + 1) +
      //         " )",
      //     });
      //     this.btnIsLoading = false;
      //     validate1 = false;
      //   }
      // });
      if (!validate1) return;

      this.submitData();
    },

    resetAll() {
      this.data.product_details.forEach((item) => {
        item.quantity = null;
        item.price = null;
        item.color = null;
        item.size = null;
        item.features = [
          {
            feature: null,
            value: null,
            values: [],
          },
        ];
      });
    },

    filterFeat(id) {
      this.features = this.features.filter((feat) => {
        return feat.id != id;
      });
    },

    setFeatuerValues(detail_index, feat_index, feet_id) {
      feet_id;
      // this.filterFeat(feet_id);

      let detailRow = this.data.product_details[detail_index];
      let featRow = detailRow.features[feat_index];

      let current_values = featRow.feature.values;
      featRow.values = current_values.map((item) => {
        return {
          id: item.id,
          value: `${item.value}`,
        };
      });
    },

    appendDetailRow() {
      this.data.product_details.push({
        quantity: null,
        price: null,
        color: null,
        size: null,
        features: [
          {
            feature: null,
            value: null,
            values: [],
          },
        ],
        media: [
          {
            image: null,
          },
        ],
      });
    },
    deleteDetailRow(index, detail_id) {
      if (detail_id) {
        this.$axios({
          method: "DELETE",
          url: `products/${this.id}/details/${detail_id}`,
        }).then(() => {
          this.data.product_details.splice(index, 1);
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("deleteSuccess"),
            position: "bottomRight",
          });
        });
      } else {
        this.data.product_details.splice(index, 1);
      }
    },
    // appendImageRow() {
    //   this.data.images.push({
    //     image: null,
    //   });
    // },
    deleteImageRow(index, img_id) {
      if (img_id) {
        this.$axios({
          method: "DELETE",
          // url: `products/${this.id}/details/images/${img_id}`,
          url: `products/image/${img_id}`,
        })
          .then(() => {
            // this.data.product_details[detail_index].media.splice(img_index, 1)
            this.data.images.splice(index, 1);
            this.$iziToast.success({
              timeout: 2000,
              message: this.$t("deleteSuccess"),
              position: "bottomRight",
            });
            setTimeout(() => {
              if (!this.data.images.length) {
                this.appendImageRow();
              }
            }, 300);
          })
          .catch((err) => {
            this.$iziToast.error({
              timeout: 2000,
              message: err.response.data.message ?? err.response.data.messages,
              position: "bottomRight",
            });
          });
      } else {
        this.data.images.splice(index, 1);
      }
      setTimeout(() => {
        if (!this.data.images.length) {
          this.appendImageRow();
        }
      }, 300);
    },

    appendFeatuer(detail_index) {
      this.data.product_details[detail_index].features.push({
        feature: null,
        value: null,
        values: [],
      });
    },
    deleteFeatuer(detail_index, feat_index) {
      this.data.product_details[detail_index].features.splice(feat_index, 1);
    },

    appendImg(detail_index) {
      this.data.product_details[detail_index].media.push({
        image: null,
      });
    },

    // Uplode Image
    uplodeImg(obj, detail_index, imgIndex) {
      this.data.product_details[detail_index].media[imgIndex].image = obj;
    },
    uplodeSizeGuide(obj) {
      this.data.size_guide = obj;
    },
    getBrands() {
      this.$axios({
        method: "GET",
        url: `get_all_brand`,
      }).then((res) => {
        this.brands = res.data.data;
      });
    },

    // getBranches() {
    //   this.$axios({
    //     method: "GET",
    //     url: `get_all_branch`,
    //   }).then((res) => {
    //     this.branches = res.data.data.map((x) => {
    //       return {
    //         id: x.id,
    //         name: x.full_name,
    //       };
    //     });
    //   });
    // },
    addBranch() {
      this.data.branches.push({
        uuid: (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1),
        branch: null,
        quantity: null,
      });
    },
    //remove ow
    removeBranch(index, id) {
      if (id) {
        this.$axios({
          method: "DELETE",
          // url: `products/${this.id}/details/images/${img_id}`,
          url: `products/branch/${id}`,
        })
          .then(() => {
            // this.data.product_details[detail_index].media.splice(img_index, 1)
            this.data.branches.splice(index, 1);
            this.$iziToast.success({
              timeout: 2000,
              message: this.$t("deleteSuccess"),
              position: "bottomRight",
            });
            this.getCurrentBranches();
            setTimeout(() => {
              if (!this.data.branches.length) {
                this.addBranch();
              }
            }, 300);
          })
          .catch((err) => {
            this.$iziToast.error({
              timeout: 2000,
              message: err.response.data.message ?? err.response.data.messages,
              position: "bottomRight",
            });
          });
      } else {
        this.data.branches.splice(index, 1);
        this.getCurrentBranches();
      }
      setTimeout(() => {
        if (!this.data.branches.length) {
          this.addBranch();
        }
      }, 300);
    },
    appendImageRow() {
      this.data.images.push({
        uuid: (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1),
        image: null,
      });
    },

    // Submit Data
    submitData() {
      const submit_data = new FormData();
      submit_data.append("_method", "PUT");
      submit_data.append("ar[name]", this.data.ar.name);
      submit_data.append("ar[desc]", this.data.ar.desc);

      submit_data.append("en[name]", this.data.en.name);
      submit_data.append("en[desc]", this.data.en.desc);
      submit_data.append("code", this.data.code || "");

      submit_data.append("is_active", +this.data.is_active);
      submit_data.append("apply_vat", +this.data.apply_vat);
      submit_data.append(
        "prescription_requirement",
        +this.data.prescription_requirement
      );
      // submit_data.append("is_replace", +this.data.is_replace);
      submit_data.append(
        "ordering",
        this.data.ordering ? this.data.ordering : ""
      );
      if (this.data.brand) {
        submit_data.append("brand_id", this.data.brand?.id);
      }
      if (this.data.type) {
        submit_data.append("type", this.data.type?.id);
      }

      submit_data.append(
        "percentage",
        this.data.percentage ? this.data.percentage : ""
      );
      submit_data.append(
        "limit_order_num",
        this.data.limit_order_num ? this.data.limit_order_num : ""
      );
      // if (this.data.percentage) {
      // }
      if (this.data.price) {
        submit_data.append(`price`, this.data.price);
      }

      if (this.data.points) {
        submit_data.append(`points`, this.data.points);
      }

      if (this.data.selected_ids.length) {
        this.data.selected_ids.map((el, index) => {
          submit_data.append(`category_ids[${index}]`, el);
        });
      }

      if (this.selectAddType) {
        this.data.images.map((image, index) => {
          if (image.image?.img_file) {
            submit_data.append(
              `products_image[${index}][image]`,
              image.image?.img_file
            );
          }
        });
      }
      submit_data.append(`is_check_all_branch`, +this.data.is_check_all_branch);
      if (!this.data.is_check_all_branch) {
        this.data.branches.map((branch, index) => {
          if (!branch.uuid) {
            submit_data.append(
              `products_branches[${index}][id]`,
              branch.branch_id
            );
          }
          submit_data.append(
            `products_branches[${index}][branch_id]`,
            branch.branch.id
          );
          submit_data.append(
            `products_branches[${index}][quantity]`,
            branch.quantity
          );
        });
      } else {
        submit_data.append(`branches_quantity`, this.data.branches_quantity);
      }
      // this.data.branches.map((branch, index) => {
      //   if (branch.branch_id) {
      //     submit_data.append(
      //       `products_branches[${index}][id]`,
      //       branch.branch_id
      //     );
      //   }
      //   submit_data.append(
      //     `products_branches[${index}][branch_id]`,
      //     branch.branch.id
      //   );
      //   submit_data.append(
      //     `products_branches[${index}][quantity]`,
      //     branch.quantity
      //   );
      // });

      this.$axios({
        method: "POST",
        url: `products/${this.id}`,
        data: submit_data,
      })
        .then(() => {
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("editSuccess"),
            position: "bottomRight",
          });
          this.$router.push({ path: "/products/show-all" });
          this.btnIsLoading = false;
        })
        .catch((err) => {
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message ?? err.response.data.messages,
            position: "bottomRight",
          });
          this.btnIsLoading = false;
        });
    },
    getCurrentBranches() {
      console.log(this.data.branches);
      let branchs = this.data.branches.map((el) => el.branch);

      this.branches = this.allBranches.filter(
        (branch) => !branchs.includes(branch)
      );
    },

    // ====== Main Categories
    getCategories() {
      this.$axios({
        method: "GET",
        url: `category_tree`,
      }).then((res) => {
        this.categories = res.data.data;
        this.rootsCategories = res.data.data;
      });
    },

    getMainData() {
      this.btnIsLoading = true;
      this.$axios({
        method: "GET",
        url: `products/${this.id}`,
      }).then((res) => {
        this.data.ar = res.data.data.ar;
        this.data.en = res.data.data.en;

        // res.data.data.categories.map((el) => {
        //   this.data.selected_ids.push(el.id);
        // });

        let ids = res.data.data.categories.map((el) => +el.id);
        this.getRootCategory(ids);
        this.data.points = res.data.data.points;
        this.data.price = res.data.data.price;
        this.data.brand = res.data.data.brand;
        this.data.type = this.types.find((el) => el.id == res.data.data.type);
        this.data.percentage = res.data.data.percentage;
        this.data.limit_order_num = res.data.data.limit_order_num;
        this.data.code = res.data.data.code;
        this.data.ordering = res.data.data.ordering;
        this.data.category = res.data.data.main_category;
        this.data.is_active = res.data.data.is_active;
        this.data.apply_vat = res.data.data.apply_vat;
        this.data.prescription_requirement =
          res.data.data.prescription_requirement;
        // this.data.is_replace = res.data.data.is_replace;
        this.data.is_check_all_branch = +res.data.data.is_check_all_branch;
        this.data.branches_quantity = res.data.data.branches_quantity;

        if (res.data.data.media.length) {
          this.data.images = res.data.data.media;
        }
        if (res.data.data.branches.length) {
          this.setBranchsRow(res.data.data.branches);
        }

        this.btnIsLoading = false;
      });
    },
    // appendCopyDetailRow(row, index) {
    //   let deepCopy = _.cloneDeep(row);
    //   deepCopy.id = null;
    //   deepCopy.media = [];
    //   deepCopy.media = [
    //     {
    //       image: null,
    //     },
    //   ];

    //   this.data.product_details.splice(index + 1, 0, deepCopy);
    // },
    getBranches() {
      this.$axios({
        method: "GET",
        url: `get_all_branch`,
      }).then((res) => {
        this.allBranches = res.data.data.map((x) => {
          return {
            id: x.id,
            name: x.full_name,
          };
        });
        this.branches = this.allBranches;
      });
    },
    uplodeProductImage(image, index) {
      this.data.images[index].image = image;
    },

    getRootCategory(id) {
      if (id.length) {
        this.$axios({
          method: "GET",
          url: `category/${id[0]}/get_root`,
        })
          .then((res) => {
            this.rootCategory = res.data.data;
            this.categories = this.categories.filter(
              (el) => el.id == res.data.data.id
            );
            this.data.selected_ids = id;
          })
          .catch(() => this.getCategories());
      } else {
        this.categories = this.rootsCategories;
      }
    },
    setBranchsRow(data) {
      let newData = JSON.parse(JSON.stringify(data));
      this.data.branches = newData.map((el) => ({
        branch: el.branch,
        quantity: el.quantity,
        branch_id: el.id,
      }));
      let branchs = [];
      this.allBranches.map((main) =>
        this.data.branches.map((sub) => {
          if (main.id != sub.branch.id) {
            const found = branchs.find((found) => found.id == sub.branch.id);

            if (!found) {
              branchs.push(main);
            }
          }
        })
      );
      this.branches = branchs;
    },
  },

  created() {
    this.getCategories();
    this.getBrands();
    this.getBranches();
    this.getMainData();
  },
};
</script>

<style lang="scss">
.product {
  .chooseImage {
    width: auto !important;
  }
}

.productDetails {
  border: 1px solid #339989;
  border-radius: 15px;
  padding-block: 20px;

  &.disabled {
    cursor: not-allowed;

    h3 {
      color: gray;
      font-size: 25px;
    }

    h4 {
      font-size: 21px;
      color: gray;
    }

    & > * {
      pointer-events: none;
    }
  }
}
</style>
