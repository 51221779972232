<template>
  <div class="wrraper">
    <!-- Start Breadcrumb -->
    <Breadcrumb
      :items="items"
      search_route="/branches/add"
      :search_title="$t('titles.addNew')"
      icon="fa-plus"
    />
    <!-- End Breadcrumb -->

    <!-- Start Statistics Card-->
    <!-- <div class="row mb-5">
      <div class="wrraper">
        <stats-card :number="statisticsItem.number"></stats-card>
      </div>
    </div> -->
    <!-- End Statistics Card-->

    <!-- Start Main Section -->
    <MainFilter @getResult="setRows" />
    <main>
      <v-data-table
        class="elevation-1 thumb diff_table"
        :headers="headers"
        :items="rows"
        :search="search"
        :loading="lodaing"
        :loading-text="$t('table.loadingData')"
        item-key="id"
        :items-per-page="paginations.items_per_page"
        hide-default-footer
      >
        <!-- ================== You Can use any slots you want ================== -->
        <!-- avatar -->
        <template v-slot:[`item.image`]="{ item }">
          <img @click="show_model_1" class="image" :src="item.avatar" />
        </template>
        <template v-slot:[`item.work_times`]="{ item }">
          <div class="_actions">
            <v-icon class="edit" small @click="editWorkTimes(item)">
              fal fa-edit
            </v-icon>
          </div>
        </template>
        <template v-slot:[`item.is_active`]="{ item }">
          <span class="status" :class="item.is_active ? 'success' : 'canceled'">
            {{ item.is_active ? $t("active") : $t("inactive") }}
          </span>
        </template>

        <!-- Select no data State -->
        <template v-slot:no-data>
          {{ $t("table.noData") }}
        </template>

        <!-- Select actions-->
        <template v-slot:[`item.actions`]="{ item }">
          <div class="_actions">
            <!-- <v-icon class="show" small @click="showItem(item)">
              fal fa-eye
            </v-icon> -->
            <v-icon class="edit" small @click="editItem(item)">
              fal fa-edit
            </v-icon>
            <v-icon class="delete" small @click="deleteItem(item)">
              fal fa-trash
            </v-icon>
          </div>
        </template>

        <!-- ======================== Start Top Section ======================== -->
        <template v-slot:top>
          <div class="d-flex justify-content-between">
            <h3 class="title table-title d-inline-flex">
              {{ $t("breadcrumb.branches.title") }} ({{
                statisticsItem.number
              }})
            </h3>
            <!-- Delete dialog -->
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5 justify-center">
                  {{ $t("table.deletedialog.areYouSure") }}
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="#1B5E20" @click="deleteItemConfirm">
                    {{ $t("table.deletedialog.ok") }}
                  </v-btn>
                  <v-btn color="#F44336" @click="dialogDelete = false">
                    {{ $t("table.deletedialog.cancel") }}
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialogDelete_selected" max-width="500px">
              <v-card>
                <v-card-title class="text-h5 justify-center">
                  {{ $t("table.deletedialog.areYouSure") }}
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="#1B5E20" @click="deleteSelectedConfirm">
                    {{ $t("table.deletedialog.ok") }}
                  </v-btn>
                  <v-btn color="#F44336" @click="dialogDelete_selected = false">
                    {{ $t("table.deletedialog.cancel") }}
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- Add & Delete -->

            <section
              class="table-buttons d-inline-flex p-0"
              style="width: fit-content"
            >
              <!-- Add -->

              <!-- Delete -->
              <button
                type="button"
                class="button_delete ml-5"
                v-if="selected.length > 0"
                @click="deleteSelected"
              >
                <i class="far fa-trash-alt"></i>
                &nbsp;
                {{ $t("table.deleteSelected") }}
              </button>
              <div class="px-5 pt-3 frm-action d-flex flex-row gap-2">
                <a
                  class="export btn"
                  href="https://shams-pharmacis.products.aait-d.com/api/dashboard/export-product-by-branch-sample"
                  download="true"
                >
                  <span> Download Sheet <i class="fal fa-download"></i></span>
                </a>
                <button class="export btn" @click="importModel = true">
                  <span> Import <i class="fal fa-upload"></i></span>
                </button>
                <button class="export btn">
                  <export-excel
                    :fetch="exportFields"
                    :meta="json_meta"
                    :name="$t(`titles.branches`)"
                    :worksheet="$t(`titles.branches`)"
                  >
                    Export <i class="fal fa-download"></i
                  ></export-excel>
                </button>
              </div>
            </section>
          </div>
        </template>
        <!-- ======================== End Top Section ======================== -->
      </v-data-table>
    </main>
    <!-- End Main Section -->

    <!-- Start Pagination -->
    <template>
      <div
        class="pagination_container text-center mb-5 d-flex justify-content-end"
      >
        <!-- <div class="select-pagination d-flex">
          <span class="first">{{ $t('show') }}</span>
          <v-select
            :items="[5, 20, 50, 100]"
            v-model="paginations.items_per_page"
          ></v-select>
          <span>{{ $t('entries') }}</span>
        </div> -->

        <v-pagination
          v-model.number="paginations.current_page"
          :length="paginations.last_page"
          :total-visible="5"
          @input="fetchData($event)"
        ></v-pagination>
      </div>
    </template>
    <!-- End Pagination -->

    <!-- Start Image_Model -->
    <base-model
      @closeModel="model_1.show_model = false"
      :show="model_1.show_model"
    >
      <div class="image">
        <img
          v-if="model_1.model_img_src"
          :src="model_1.model_img_src"
          alt="..."
        />
      </div>
    </base-model>
    <!-- End Image_Model -->
    <v-dialog v-model="importModel" width="500">
      <v-card>
        <!-- START:: FILE INPUT -->
        <v-card-text>
          <div class="input_wrapper top_label file_input">
            <span class="file_input_label"> تحميل الملف </span>
            <label
              for="file_input_1"
              class="form-label"
              v-if="!selectedFile.file"
            ></label>
            <label for="file_input_1" class="form-label" v-else>
              {{ selectedFile.name }}
            </label>
            <input
              type="file"
              class="form-control"
              id="file_input_1"
              @change="handelSelectedFile"
            />
          </div>

          <!-- END:: FILE INPUT -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#1B5E20"
            :loading="importLoading"
            @click="importFileConfirm"
          >
            {{ $t("table.deletedialog.ok") }}
          </v-btn>
          <v-btn color="#F44336" @click="importModel = false">
            {{ $t("table.deletedialog.cancel") }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MainFilter from "@/components/Filters/MainFilter.vue";

export default {
  components: {
    MainFilter,
  },
  data() {
    return {
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.branches.title"),
          disabled: false,
          href: "/branches/show-all",
        },
        {
          text: this.$t("breadcrumb.branches.all"),
          disabled: true,
          href: "",
        },
      ],

      // ========== Statistics
      statisticsItem: {},

      // ========== Top Section
      search: "",

      // ========== dialog Status
      dialogDelete: false,
      dialogDelete_selected: false,
      itemtoDelete: null,

      // ========== Model
      model_1: {
        show_model: false,
        model_img_src: "",
      },

      // ========== Body Section
      calories: "",

      // ========== Your Data
      rows: [], // injected in created

      // ========== elected Data
      selected: [],
      singleSelect: false,

      // ========== pagination
      paginations: {
        current_page: 1,
        last_page: 2,
        items_per_page: 20,
      },

      // ========== Loding
      lodaing: false,
      selectedFile: {
        file: null,
        name: null,
        path: null,
      },
      importModel: false,
      importLoading: false,
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
    };
  },

  computed: {
    ...mapGetters({
      lang: "lang_module/lang",
    }),

    headers() {
      if (this.lang == "ar") {
        return [
          {
            text: "#",
            align: "cenetr",
            value: "id",
            width: "80",
            sortable: true,
          },
          {
            text: "الصوره",
            align: "start",
            value: "image",

            sortable: true,
          },
          {
            text: "اسم الفرع",
            align: "center",
            value: "full_name",
            sortable: false,
          },
          {
            text: "رقم الهاتف",
            align: "center",
            value: "phone_complete_form",
            sortable: false,
          },
          {
            text: "مفعل؟",
            align: "start",

            value: "is_active",
            sortable: true,
          },
          {
            text: "تعديل أوقات العمل",
            align: "center",

            value: "work_times",
            sortable: false,
          },
          // {
          //   text: 'متاح للشحن',
          //   align: 'center',
          //   value: 'is_shapping',
          //   sortable: false,
          // },
          // {
          //   text: 'تكلفة الشحن',
          //   align: 'center',
          //   value: 'shipping_price',
          //   sortable: false,
          // },

          {
            text: "التحكم",
            value: "actions",
            sortable: false,
            align: "center",
          },
        ];
      } else {
        return [
          {
            text: "#",
            align: "cenetr",
            value: "id",
            width: "80",
            sortable: true,
          },
          {
            text: "Image",
            align: "start",
            value: "image",

            sortable: true,
          },
          {
            text: "Name",
            align: "center",
            value: "full_name",
            sortable: false,
          },
          {
            text: "Phone",
            align: "center",
            value: "phone_complete_form",
            sortable: false,
          },
          {
            text: "Active?",
            align: "start",

            value: "is_active",
            sortable: true,
          },
          {
            text: "Edit work times",
            align: "center",

            value: "work_times",
            sortable: false,
          },
          // {
          //   text: 'متاح للشحن',
          //   align: 'center',
          //   value: 'is_shapping',
          //   sortable: false,
          // },
          // {
          //   text: 'تكلفة الشحن',
          //   align: 'center',
          //   value: 'shipping_price',
          //   sortable: false,
          // },

          {
            text: "Control",
            value: "actions",
            sortable: false,
            align: "center",
          },
        ];
      }
    },
  },

  methods: {
    async exportFields() {
      const data = await this.$axios({
        method: "GET",
        url: "get_all_branch",
      });
      let newData = [];
      data.data.data.forEach((el) => {
        let newObject = {};
        newObject[`${this.$t("forms.labels.image")}`] = el.avatar;
        newObject[`${this.$t("forms.labels.name")}`] = el.full_name;
        newObject[`${this.$t("forms.labels.phone")}`] = el.phone_complete_form;
        newObject[`${this.$t("forms.labels.status")}`] = el.is_active
          ? this.$t("active")
          : this.$t("inactive");
        // newObject[`${this.$t("forms.labels.city")}`] = el.city;
        newData.push(newObject);
      });
      return newData;
    },
    handelSelectedFile(e) {
      this.selectedFile.file = e.target.files[0];
      this.selectedFile.name = e.target.files[0].name;
      this.selectedFile.path = URL.createObjectURL(e.target.files[0]);
    },
    // exportProducts() {
    //   this.$axios.get("products-export");
    // },

    importFileConfirm() {
      if (!this.selectedFile.file) {
        this.$iziToast.error({
          timeout: 2000,
          message: "يرجي اختيار الملف اولا ",
          position: "bottomRight",
        });
        return;
      } else {
        this.importLoading = true;
        const frmData = new FormData();
        frmData.append("branch_products_file", this.selectedFile.file);
        this.$axios
          .post("import-file-branch-products", frmData)
          .then(() => {
            this.importModel = false;
            this.importLoading = false;
            this.$iziToast.success({
              timeout: 2000,
              message: "تم التحميل بنجاح",
              position: "bottomRight",
            });
            this.selectedFile = {
              file: null,
              name: null,
              path: null,
            };
            this.setRows();
          })
          .catch((err) => {
            // this.importModel = false;
            this.importLoading = false;
            this.$iziToast.error({
              timeout: 2000,
              message: err.response.data.message,
              position: "bottomRight",
            });
          });
      }
    },
    // ===== Search Method =====
    filterClick(word) {
      if (!this.lodaing) {
        this.search = word;
        this.helper_filterSearch();
      }
    },

    // img Model
    show_model_1(e) {
      this.model_1.model_img_src = e.target.src;
      this.model_1.show_model = true;
    },

    // ==================== Start CRUD ====================
    addItem() {
      this.$router.push({ path: "/branches/add" });
    },
    showItem(item) {
      this.$router.push({ path: "/branches/show/" + item.id });
    },
    editItem(item) {
      this.$router.push({ path: "/branches/edit/" + item.id });
    },
    editWorkTimes(item) {
      this.$router.push({
        path: "/work-time/show-all",
        query: { branch_id: item.id },
      });
    },
    // ===== Delete
    deleteItem(item) {
      this.dialogDelete = true;
      this.itemtoDelete = item;
    },
    deleteItemConfirm() {
      this.$axios({
        method: "DELETE",
        url: `branches/${this.itemtoDelete.id}`,
      })
        .then(() => {
          this.rows = this.rows.filter((item) => {
            return item.id != this.itemtoDelete.id;
          });
          this.dialogDelete = false;
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("deleteSuccess"),
            position: "bottomRight",
          });
        })
        .catch((err) => {
          this.dialogDelete = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "bottomRight",
          });
        });
    },
    deleteSelected() {
      this.dialogDelete_selected = true;
    },
    deleteSelectedConfirm() {
      this.$axios({
        method: "POST",
        url: `branches/deleteAll`,
        data: { ids: this.selected.map((item) => item.id) },
      })
        .then(() => {
          this.rows = this.rows.filter((item) => {
            return !this.selected.filter((obj) => obj.id == item.id).length > 0;
          });
          this.dialogDelete_selected = false;
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("deleteSuccess"),
            position: "bottomRight",
          });
        })
        .catch((err) => {
          this.dialogDelete_selected = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "bottomRight",
          });
        });
    },
    // ==================== End CRUD ====================

    // Set Rows
    setRows(e) {
      this.lodaing = true;
      console.log(e);
      this.$axios({
        method: "GET",
        url: "branches",
        params: {
          page: this.paginations.current_page,
          keyword: e?.searchInput || "",
          start_date: e?.dateStart || "",
          end_date: e?.dateEnd || "" || "",
        },
      })
        .then((res) => {
          this.paginations.last_page = res.data.meta.last_page;
          this.paginations.items_per_page = res.data.meta.per_page;

          this.rows = res.data.data;

          this.statisticsItem.number = res.data.meta.total;

          this.lodaing = false;
        })
        .catch((err) => {
          this.lodaing = false;
        });
    },
    fetchData(e) {
      this.$router
        .push({
          query: Object.assign({}, this.$route.query, {
            page: e,
          }),
        })
        .catch(() => {});

      this.setRows();
    },
  },
  created() {
    if (this.$route.query.page) {
      this.paginations.current_page = +this.$route.query.page;
    }
    this.setRows();
  },

  // ======= hooks
};
</script>

<style lang="scss" scoped></style>
