<template>
  <div class="create_wrapper">
    <!-- Start Breadcrumb -->
    <Breadcrumb :items="items" />
    <!-- End Breadcrumb -->

    <div class="custom_card">
      <div class="card-header">
        <h4 class="card-title">{{ $t("addNew") }}</h4>
      </div>

      <!-- ==== Start Form ==== -->
      <form @submit.prevent="validateCreateForm">
        <div class="container">
          <div class="row justify-content-between">
            <div class="col-lg-12 py-0">
              <uplode-image
                @inputChanged="uplodeImg_1"
                placeHolder="الصورة "
              ></uplode-image>
            </div>
            <div class="col-lg-12 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model="data.code"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.code") }}
                </label>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="number"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model="data.use"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.use_code") }}
                </label>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="number"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model="data.max_users"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.max_use_users") }}
                </label>
              </div>
            </div>

            <div class="col-lg-4 py-0">
              <div class="input_wrapper top_label">
                <label class="form-label">نوع الخصم</label>
                <multiselect
                  v-model="data.discount_type"
                  :options="discount_types"
                  label="name"
                  track-by="id"
                  placeholder=" "
                  :searchable="true"
                  :allow-empty="false"
                  :show-labels="false"
                ></multiselect>
              </div>
            </div>

            <div class="col-lg-4 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="number"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model="data.discount"
                  :max="data.discount_type.id == 'percentage' ? 100 : ''"
                />

                <label for="name_input" class="form-label">
                  {{
                    data.discount_type.id == "percentage"
                      ? $t("forms.labels.discount")
                      : data.discount_type.id == "value"
                      ? $t("forms.labels.discountValue")
                      : $t("forms.labels.discountPoints")
                  }}
                </label>
              </div>
            </div>

            <div class="col-lg-4 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="number"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model="data.max_discount"
                  :max="data.discount_type.id == 'percentage' ? 100 : ''"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.max_discount") }}
                </label>
              </div>
            </div>

            <!-- START:: DATE PICKER INPUT -->
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label date_input">
                <label class="form-label">
                  {{ $t("forms.labels.start_date") }}
                </label>
                <v-menu
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="data.start_date"
                      label="Picker without buttons"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="data.start_date"
                    @input="dateMenu = false"
                    :allowed-dates="disablePastDates"
                    no-title
                  ></v-date-picker>
                </v-menu>
              </div>
            </div>
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label date_input">
                <label class="form-label">
                  {{ $t("forms.labels.start_time") }}
                </label>
                <v-dialog
                  ref="start_time_dialog"
                  v-model="start_time_modal"
                  :return-value.sync="data.start_time"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="data.start_time"
                      label="Picker in dialog"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="start_time_modal"
                    v-model="data.start_time"
                    full-width
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="start_time_modal = false"
                    >
                      الغاء
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.start_time_dialog.save(data.start_time)"
                    >
                      تم
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
              </div>
            </div>
            <!-- END:: DATE PICKER INPUT -->

            <!-- START:: DATE PICKER INPUT -->
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label date_input">
                <label class="form-label">
                  {{ $t("forms.labels.end_date") }}
                </label>
                <v-menu
                  v-model="dateMenu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="data.end_date"
                      label="Picker without buttons"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="data.end_date"
                    @input="dateMenu2 = false"
                    :allowed-dates="disablePastDates2"
                    no-title
                  ></v-date-picker>
                </v-menu>
              </div>
            </div>
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label date_input">
                <label class="form-label">
                  {{ $t("forms.labels.end_time") }}
                </label>
                <v-dialog
                  ref="dialog"
                  v-model="end_time_modal"
                  :return-value.sync="data.end_time"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="data.end_time"
                      label="Picker in dialog"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="end_time_modal"
                    v-model="data.end_time"
                    full-width
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="end_time_modal = false">
                      الغاء
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(data.end_time)"
                    >
                      تم
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
              </div>
            </div>
            <!-- END:: DATE PICKER INPUT -->

            <!-- Start:: Is Active -->
            <div class="col-lg-6 py-0">
              <v-checkbox
                :label="$t('forms.labels.is_active')"
                v-model="data.is_active"
                color="success"
              ></v-checkbox>
            </div>
            <!-- End:: Is Active -->
          </div>
        </div>

        <div class="buttons_wrapper">
          <button
            class="button_style_1"
            :class="btnIsLoading ? 'disabled' : ''"
          >
            {{ $t("forms.submit") }}
            <span class="btn_loader" v-if="btnIsLoading"></span>
          </button>
        </div>
      </form>
      <!-- ==== End Form ==== -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",

  data() {
    return {
      dateMenu: false,
      dateMenu2: false,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.promo_code.title"),
          disabled: false,
          href: "/promo-code",
        },
        {
          text: this.$t("breadcrumb.promo_code.add"),
          disabled: true,
          href: "",
        },
      ],

      // ========== Loading
      btnIsLoading: false,

      // ========== create_data
      data: {
        image: null,
        code: null,
        use: null,
        discount: null,
        end_date: null,
        start_date: null,
        is_active: false,
        discount_type: {
          id: "value",
          name: "قيمة",
        },
        applay_on: null,
        max_discount: null,
        max_users: null,
        use: null,
        addition_option: null,
        apply_ids: [],
        products_rows: [
          {
            product: null,
            product_details_show: [],
            product_details_ids: [],
            selected: [],
          },
        ],
        categories_rows: [
          {
            category: null,
            selected_categories: [],
          },
        ],
        start_time: null,
        end_time: null,
      },
      start_time_modal: false,
      end_time_modal: false,
      products: [],
      // Fixed Data
      categories: [],
      sub_categories: [],

      addition_options: [
        {
          id: "free_shipping",
          name: "توصيل مجاني",
        },
      ],
      appliedCoupoun: [
        {
          id: "all",
          name: this.$t("appliedCoupoun.all"),
        },
        {
          id: "special_products",
          name: this.$t("appliedCoupoun.special_products"),
        },
        {
          id: "except_products",
          name: this.$t("appliedCoupoun.except_products"),
        },
        {
          id: "special_categories",
          name: this.$t("appliedCoupoun.special_categories"),
        },
        {
          id: "except_categories",
          name: this.$t("appliedCoupoun.except_categories"),
        },
      ],
    };
  },

  computed: {
    discount_types() {
      return [
        {
          id: "value",
          name: "قيمة",
        },
        {
          id: "percentage",
          name: "نسبة من اجمالي المشتريات",
        },
      ];
    },
    headers() {
      return [
        {
          text: "المنتج",
          align: "center",
          value: "item",
          sortable: true,
        },
      ];
    },
  },

  methods: {
    uplodeImg_1(obj) {
      this.data.image = obj;
    },
    all_apply_ids(ids, index) {
      if (this.data.applay_on.id != "all") {
        this.data.apply_ids[index] = ids;
      }
    },

    //Products
    appendProductRow() {
      this.data.products_rows.push({
        product: null,
        product_details_show: [],
        product_details_ids: [],
        selected: [],
      });
    },
    deleteProductRow(index) {
      this.data.products_rows.splice(index, 1);
    },
    nameWithCode({ name, code }) {
      return `${name} — كود ${code}`;
    },
    subCatWithParent({ name, parent }) {
      return `${name} —  (${parent})`;
    },

    getProductDetails(index, id) {
      this.$axios({
        method: "GET",
        url: `products/${id}`,
      }).then((res) => {
        this.data.products_rows[index].product_details_ids = [];
        this.data.products_rows[index].product_details_show =
          res.data.data.product_details;
      });
    },

    appendCategoryRow() {
      this.data.categories_rows.push({
        category: null,
        selected_categories: [],
      });
    },
    deleteCategoryRow(index) {
      this.data.categories_rows.splice(index, 1);
    },

    disablePastDates(val) {
      return val >= new Date().toISOString().substr(0, 10);
    },
    disablePastDates2(val) {
      return val >= new Date(this.data.start_date).toISOString().substr(0, 10);
    },

    // Validate Data
    validateCreateForm() {
      this.btnIsLoading = true;

      if (!this.data.image) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.image"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.code) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.code"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.use) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.use"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.max_users) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.max_users"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (+this.data.max_users > +this.data.use) {
        this.$iziToast.error({
          timeout: 3000,
          message:
            "عدد مرات الاستخدام يجب ان يكون اكبر من اقصي عدد استخدام لليوزر الواحد",
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.discount) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.discount"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.max_discount) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.max_discount"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.start_date) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.start_date"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.start_time) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.start_time"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.end_time) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.end_time"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.end_date) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.end_date"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else {
        this.submitData();
        return;
      }
    },

    // Submit Data
    submitData() {
      const submit_data = new FormData();
      if (this.data.image?.img_file) {
        submit_data.append("image", this.data.image?.img_file);
      }
      submit_data.append("code", this.data.code);
      submit_data.append(
        "start_at",
        `${this.data.start_date} ${this.data.start_time}`
      );
      submit_data.append(
        "end_at",
        `${this.data.end_date} ${this.data.end_time}`
      );
      submit_data.append("discount_amount", this.data.discount);
      submit_data.append("discount_type", this.data.discount_type.id);
      submit_data.append("max_discount", this.data.max_discount);
      submit_data.append("max_used_num", this.data.use);
      submit_data.append("max_used_for_user", this.data.max_users);
      submit_data.append("is_active", +this.data.is_active);

      this.$axios({
        method: "POST",
        url: "coupon",
        data: submit_data,
      })
        .then(() => {
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("addSuccess"),
            position: "bottomRight",
          });
          this.$router.push({ path: "/promoCode" });
          this.btnIsLoading = false;
        })
        .catch((err) => {
          let message = err.response.data.message ?? err.response.data.messages;
          this.$iziToast.error({
            timeout: 2000,
            message: message,
            position: "bottomRight",
          });
          this.btnIsLoading = false;
        });
    },
    // ====== Products
    getProducts() {
      this.$axios({
        method: "GET",
        url: `product/without_paginate`,
      }).then((res) => {
        this.products = res.data.data.map((item) => {
          return {
            id: item.id,
            code: item.code,
            name: item.name,
          };
        });
      });
    },
    // ====== Main Categories
    getCategories() {
      this.$axios({
        method: "GET",
        url: `categories`,
      }).then((res) => {
        this.categories = res.data.data.map((item) => {
          return {
            id: item.id,

            name: item.name,
          };
        });
      });
    },

    // ====== Sub Categories
    getSubCategories(id) {
      this.$axios({
        method: "GET",
        url: `category/${id}/third_level`,
      }).then((res) => {
        this.data.sub_categories = [];
        this.sub_categories = res.data.data.map((item) => {
          return {
            id: item.id,
            parent: item.parent.label,
            name: item.name,
          };
        });
      });
    },
    resetAll() {
      this.data.products_rows = {
        product: null,
        product_details_show: [],
        product_details_id: "",
      };
      this.data.categories_rows = {
        category: null,
        selected_categories: [],
      };
    },
  },

  created() {
    this.getProducts();
    this.getCategories();
  },
};
</script>

<style lang="scss">
.productRow {
  border: 1px solid #339989;
  border-radius: 15px;
  padding: 10px 10px 20px 10px;
  margin-bottom: 20px;

  .detail {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
    .detail_show {
      border: 1px solid #339989;
      padding: 10px;
      flex-grow: 1;
      border-radius: 15px;
      position: relative;
      min-height: 100px;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .rate {
        color: gray;
        position: absolute;
        top: 5px;
        left: 5px;
        font-size: 13px;

        span {
          color: yellow;
        }
      }

      .image {
        img {
          max-height: 50px;
        }
      }

      .color,
      .size {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      .image {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      .key {
        margin-bottom: 5px;
      }
    }
  }
}
</style>
