<template>
  <div class="create_wrapper">
    <!-- Start Breadcrumb -->
    <Breadcrumb :items="items" />
    <!-- End Breadcrumb -->

    <div class="custom_card">
      <div class="card-header">
        <h4 class="card-title">{{ $t("edit") }}</h4>
      </div>

      <!-- ==== Start Form ==== -->
      <form @submit.prevent="validateCreateForm">
        <div class="container">
          <div class="row justify-content-between">
            <!-- Start:: Is Active -->
            <div class="col-12 py-0">
              <v-checkbox
                :label="$t('forms.labels.is_active')"
                v-model="data.is_active"
                color="success"
              ></v-checkbox>
            </div>
            <!-- End:: Is Active -->
            <!-- Start:: Image Ar -->
            <div class="col-lg-6">
              <uplode-image
                @inputChanged="uplodeImg_1"
                :placeHolder="$t('forms.labels.image_ar')"
                :data_src="data.ar.image"
              ></uplode-image>
            </div>
            <!-- End:: Image Ar -->

            <!-- Start:: Image Ar -->
            <div class="col-lg-6">
              <uplode-image
                @inputChanged="uplodeImg_2"
                :placeHolder="$t('forms.labels.image_en')"
                :data_src="data.en.image"
              ></uplode-image>
            </div>
            <!-- End:: Image Ar -->

            <!-- Start:: Ar title -->
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.ar.title"
                  @keypress="isArabic($event)"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.title_ar") }}
                </label>
              </div>
            </div>
            <!-- End:: Ar title -->

            <!-- Start:: En title -->
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.en.title"
                  @keypress="isEnglish($event)"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.title_en") }}
                </label>
              </div>
            </div>
            <!-- End:: En title -->

            <!-- Start:: En slug -->
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="number"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.ordering"
                  @keypress="preventMinus"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.ordering") }}
                </label>
              </div>
            </div>
            <!-- End:: En slug -->
            <!-- Start:: Categories -->
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <label class="form-label">
                  {{ $t("forms.labels.category") }}
                </label>
                <multiselect
                  :loading="loading"
                  v-model="data.category"
                  :options="categories"
                  label="label"
                  track-by="id"
                  placeholder=" "
                  @input="getProducts($event)"
                  :searchable="true"
                  :allow-empty="false"
                  :show-labels="false"
                ></multiselect>
              </div>
            </div>
            <!-- End:: Categories -->

            <!-- Start:: Products -->
            <div class="col-lg-12 py-0">
              <div class="input_wrapper top_label">
                <label class="form-label">{{ $t("titles.products") }}</label>
                <multiselect
                  :loading="loading"
                  v-model="data.product"
                  :options="products"
                  label="name"
                  track-by="id"
                  placeholder=" "
                  :searchable="true"
                  :multiple="true"
                  :allow-empty="false"
                  :show-labels="false"
                ></multiselect>
              </div>
            </div>
            <!-- End:: Products -->

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label date_input">
                <label class="form-label">
                  {{ $t("forms.labels.start_date") }}
                </label>
                <v-menu
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="data.start_date"
                      label="Picker without buttons"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="data.start_date"
                    @input="dateMenu = false"
                    no-title
                  ></v-date-picker>
                </v-menu>
              </div>
            </div>
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label date_input">
                <label class="form-label">
                  {{ $t("forms.labels.start_time") }}
                </label>
                <v-dialog
                  ref="start_time_dialog"
                  v-model="start_time_modal"
                  :return-value.sync="data.start_time"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="data.start_time"
                      label="Picker in dialog"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="start_time_modal"
                    v-model="data.start_time"
                    full-width
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="start_time_modal = false"
                    >
                      {{ $t("cancel") }}
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.start_time_dialog.save(data.start_time)"
                    >
                      {{ $t("done") }}
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
              </div>
            </div>
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label date_input">
                <label class="form-label">
                  {{ $t("forms.labels.end_date") }}
                </label>
                <v-menu
                  v-model="dateMenu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="data.end_date"
                      label="Picker without buttons"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="data.end_date"
                    @input="dateMenu2 = false"
                    no-title
                  ></v-date-picker>
                </v-menu>
              </div>
            </div>
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label date_input">
                <label class="form-label">
                  {{ $t("forms.labels.end_time") }}
                </label>
                <v-dialog
                  ref="dialog"
                  v-model="end_time_modal"
                  :return-value.sync="data.end_time"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="data.end_time"
                      label="Picker in dialog"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="end_time_modal"
                    v-model="data.end_time"
                    full-width
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="end_time_modal = false">
                      {{ $t("cancel") }}
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(data.end_time)"
                    >
                      {{ $t("done") }}
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
              </div>
            </div>

            <div class="col-12 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="url"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model="data.link"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.link") }}
                </label>
              </div>
            </div>
            <!-- Start:: Types -->
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <label class="form-label">
                  {{ $t("forms.labels.type") }}
                </label>
                <multiselect
                  v-model="data.type"
                  :options="types"
                  label="name"
                  track-by="id"
                  placeholder=" "
                  :searchable="true"
                  :allow-empty="true"
                  :show-labels="false"
                ></multiselect>
              </div>
            </div>
            <!-- End:: Types -->

            <!-- Start:: PlatForm -->
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <label class="form-label">
                  {{ $t("forms.labels.platform") }}
                </label>
                <multiselect
                  v-model="data.platform"
                  :options="platforms"
                  label="name"
                  track-by="id"
                  placeholder=" "
                  :searchable="true"
                  :allow-empty="true"
                  :show-labels="false"
                ></multiselect>
              </div>
            </div>
            <!-- End:: PlatForm -->
            <!-- Start:: Ar desc -->
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <label for="textarea_1" class="form-label">
                  {{ $t("forms.labels.desc_ar") }}
                </label>
                <ckeditor class="col-12" v-model="data.ar.desc" />
              </div>
            </div>
            <!-- End:: Ar desc -->

            <!-- Start:: En desc -->
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <label for="textarea_1" class="form-label">
                  {{ $t("forms.labels.desc_en") }}
                </label>
                <ckeditor class="col-12" v-model="data.en.desc" />
              </div>
            </div>
            <!-- End:: En desc -->
          </div>
        </div>

        <div class="buttons_wrapper">
          <button
            class="button_style_1"
            :class="btnIsLoading ? 'disabled' : ''"
            :disabled="btnIsLoading"
          >
            {{ $t("forms.submit") }}
            <span class="btn_loader" v-if="btnIsLoading"></span>
          </button>
        </div>
      </form>
      <!-- ==== End Form ==== -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",

  props: ["id"],

  data() {
    return {
      dateMenu: false,
      dateMenu2: false,
      start_time_modal: false,
      end_time_modal: false,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.slider.all"),
          disabled: false,
          href: "/slider/show-all",
        },
        {
          text: this.$t("breadcrumb.about.edit"),
          disabled: true,
          href: "",
        },
      ],

      categories: [],
      products: [],

      // ========== Loading
      loading: false,
      btnIsLoading: false,

      // ========== create_data
      data: {
        ar: {
          image: null,
          title: null,
          desc: null,
          slug: null,
        },
        en: {
          image: null,
          title: null,
          desc: null,
          slug: null,
        },
        start_time: null,
        end_time: null,
        category: null,
        platform: null,
        type: null,
        product: null,
        ordering: null,
        start_date: null,
        link: null,
        is_active: true,
        end_date: null,
      },
    };
  },

  methods: {
    // Uplode Image
    uplodeImg_1(obj) {
      this.data.ar.image = obj;
    },
    // Uplode Image
    uplodeImg_2(obj) {
      this.data.en.image = obj;
    },

    // Validate Data
    validateCreateForm() {
      this.btnIsLoading = true;
      if (!this.data.ar.image || !this.data.en.image) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.image"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
      } else if (!this.data.ar.title || !this.data.en.title) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.title"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.category && !this.data.link) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.category"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.start_date) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.start_date"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.start_time) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.start_time"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.end_date) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.end_date"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.end_time) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.end_time"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.type) {
        this.$iziToast.error({
          timeout: 2000,
          message: "النوع مطلوب",
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.ordering) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.ordering"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (this.data.ordering && this.data.ordering < 0) {
        this.$iziToast.error({
          timeout: 2000,
          // message: "  الترتيب يجب ان يكون اكبر من او يساوي 0",
          message: this.$t("forms.validation.orderingLength"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else {
        this.submitData();
        return;
      }
    },

    fixedData() {
      this.loading = true;
      this.$axios({
        method: "GET",
        url: "get_all_main_category",
      })
        .then((res) => {
          this.loading = false;
          this.categories = res.data.data;
        })
        .catch(() => (this.loading = false));
      // this.$axios({
      //   method: "GET",
      //   url: "get_all_product",
      // })
      //   .then((res) => {
      //     this.loading = false;
      //     this.products = res.data.data.map((el) => {
      //       return {
      //         id: el.id,
      //         name: el.name,
      //       };
      //     });
      //   })
      //   .catch(() => (this.loading = false));
    },
    getProducts(category, products) {
      this.loading = true;
      this.$axios({
        method: "GET",
        url: `category/${category.id}/products`,
      })
        .then((res) => {
          this.loading = false;
          this.products = res.data.data.map((el) => {
            return {
              id: el.id,
              name: el.name,
            };
          });
          if (products) this.data.product = products;
        })
        .catch(() => (this.loading = false));
    },

    //GET DATA
    getData() {
      this.$axios({
        method: "GET",
        url: `slider/${this.id}`,
      }).then((res) => {
        this.data.ar.image = res.data.data.images.ar;
        this.data.en.image = res.data.data.images.en;

        this.data.ar.title = res.data.data.ar?.name;
        this.data.en.title = res.data.data.en?.name;

        this.data.category = res.data.data.category;
        this.data.link = res.data.data.link;
        this.data.start_date = res.data.data.start_at;
        this.data.start_time = res.data.data.start_time;
        this.data.end_date = res.data.data.end_at;
        this.data.end_time = res.data.data.end_time;
        this.data.is_active = res.data.data.is_active;

        this.data.type = this.types.find((el) => el.id == res.data.data.type);
        this.data.platform = this.platforms.find(
          (el) => el.id == res.data.data.platform
        );

        this.data.ar.desc = res.data.data.ar?.desc;
        this.data.en.desc = res.data.data.en?.desc;
        // this.data.product = res.data.data.products;
        if (res.data.data.products.length) {
          this.getProducts(this.data.category, res.data.data.products);
        }
        this.data.ordering = res.data.data.ordering;
      });
    },
    //GET DATA

    // Submit Data
    submitData() {
      const submit_data = new FormData();

      submit_data.append("_method", "PUT");

      if (this.data.ar.image?.img_file) {
        submit_data.append("ar[image]", this.data.ar.image?.img_file || "");
      }
      if (this.data.en.image?.img_file) {
        submit_data.append("en[image]", this.data.en.image?.img_file || "");
      }

      submit_data.append("ar[name]", this.data.ar.title || "");
      submit_data.append("en[name]", this.data.en.title || "");

      submit_data.append("ar[desc]", this.data.ar.desc || "");
      submit_data.append("en[desc]", this.data.en.desc || "");

      submit_data.append("ar[slug]", this.data.ar.slug || "");
      submit_data.append("en[slug]", this.data.en.slug || "");

      if (this.data.link) {
        submit_data.append("link", this.data.link);
      } else {
        submit_data.append("link", "");
        submit_data.append("category_id", this.data.category?.id || "");

        if (this.data.product) {
          this.data.product.map((ele, index) => {
            submit_data.append(`product_apply_ids[${index}]`, ele.id);
          });
        }
      }

      submit_data.append(
        "start_at",
        `${this.data.start_date} ${this.data.start_time}`
      );
      submit_data.append(
        "end_at",
        `${this.data.end_date} ${this.data.end_time}`
      );
      // submit_data.append("start_at", this.data.start_date);
      // submit_data.append("end_at", this.data.end_date);
      submit_data.append("is_active", +this.data.is_active);
      submit_data.append("ordering", this.data.ordering || "");
      submit_data.append("platform", this.data.platform?.id || "");
      if (this.data.type) {
        submit_data.append("type", this.data.type?.id);
      }

      this.$axios({
        method: "POST",
        url: `slider/${this.id}`,
        data: submit_data,
      })
        .then(() => {
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("editSuccess"),
            position: "bottomRight",
          });
          this.$router.push({ path: "/slider/show-all" });
          this.btnIsLoading = false;
        })
        .catch((err) => {
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "bottomRight",
          });
          this.btnIsLoading = false;
        });
    },
  },

  computed: {
    types() {
      return [
        {
          id: "divided",
          name: "منقسم",
        },
        {
          id: "banner",
          name: "بانر",
        },
        {
          id: "quik_delivery",
          name: "توصيل سريع",
        },
        {
          id: "middle",
          name: "المنتصف",
        },
      ];
    },
    platforms() {
      return [
        {
          id: "all",
          name: "الكل",
        },
        {
          id: "app",
          name: "تطبيق",
        },
        {
          id: "website",
          name: "موقع",
        },
      ];
    },
  },

  created() {
    this.getData();
    this.fixedData();
  },
};
</script>
