<template>
  <div class="create_wrapper">
    <!-- Start Breadcrumb -->
    <Breadcrumb :items="items" />
    <!-- End Breadcrumb -->

    <div class="custom_card">
      <div class="card-header">
        <h4 class="card-title">{{ $t("addNew") }}</h4>
      </div>

      <!-- ==== Start Form ==== -->
      <form @submit.prevent="validateCreateForm">
        <div class="container">
          <div class="row justify-content-start">
            <!-- Image -->
            <upload-image-direct
              @inputChanged="uplodeImg_1"
              model="users"
            ></upload-image-direct>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty, addDataLocalStorage()"
                  v-model.trim="data.name"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.name") }}
                </label>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="number"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty, addDataLocalStorage()"
                  v-model.trim="data.phone"
                  @keypress="isEnglish($event)"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.phone") }}
                </label>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <label class="form-label">
                  {{ $t("forms.labels.country") }}
                </label>
                <multiselect
                  v-model="data.country"
                  :options="countries"
                  label="name"
                  track-by="id"
                  placeholder=""
                  @input="getCities(), (data.city = null)"
                  :searchable="true"
                  :allow-empty="true"
                  :show-labels="false"
                ></multiselect>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <label class="form-label">
                  {{ $t("forms.labels.city") }}
                </label>
                <multiselect
                  v-model="data.city"
                  :options="cities"
                  label="name"
                  track-by="id"
                  placeholder=""
                  :searchable="true"
                  :allow-empty="true"
                  :show-labels="false"
                ></multiselect>
              </div>
            </div>

            <!-- Start:: Password -->
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <div class="d-flex align-items-center gap-2 w-100">
                  <input
                    :type="showPassword ? 'password' : 'text'"
                    class="form-control"
                    @input="helper_checkIfInputIsEmpty"
                    v-model.trim="data.password"
                  />
                  <button
                    class="p-0 btn"
                    type="button"
                    @click="showPassword = !showPassword"
                  >
                    <i class="fas fa-eye"></i>
                  </button>
                </div>
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.password") }}
                </label>
              </div>
            </div>
            <!-- End:: Password -->

            <!-- Start:: Password  Confirmation-->
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <div class="d-flex align-items-center gap-2 w-100">
                  <input
                    :type="showCPassword ? 'password' : 'text'"
                    class="form-control"
                    @input="helper_checkIfInputIsEmpty"
                    v-model.trim="data.password_confirmation"
                  />
                  <button
                    class="p-0 btn"
                    type="button"
                    @click="showCPassword = !showCPassword"
                  >
                    <i class="fas fa-eye"></i>
                  </button>
                </div>
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.confirmPassword") }}
                </label>
              </div>
            </div>
            <!-- End:: Password  Confirmation-->

            <!-- Start:: Is Active -->
            <div class="col-lg-12 py-0">
              <v-checkbox
                :label="$t('check_all_drivers')"
                v-model="data.is_check_all_drivers"
                color="success"
              ></v-checkbox>
              <div
                v-if="!data.is_check_all_drivers"
                class="input_wrapper top_label"
              >
                <label class="form-label">
                  {{ $t("labels.driver") }}
                </label>
                <multiselect
                  v-model="data.driver"
                  :options="drivers"
                  label="name"
                  track-by="id"
                  placeholder=" "
                  :searchable="true"
                  :allow-empty="true"
                  :show-labels="false"
                  :multiple="true"
                ></multiselect>
              </div>
            </div>

            <!-- End:: Is Active -->
            <!-- Start:: Is Active -->
            <div class="col-lg-6 py-0">
              <v-checkbox
                :label="$t('active')"
                v-model="data.is_active"
                color="success"
              ></v-checkbox>
            </div>
            <!-- End:: Is Active -->
            <div class="col-lg-6 py-0" v-if="data.is_active">
              <v-checkbox
                :label="$t('has_pickup')"
                v-model="data.has_pickup"
                color="success"
              ></v-checkbox>
              <v-checkbox
                :label="$t('has_delivery')"
                v-model="data.has_delivery"
                color="success"
              ></v-checkbox>
            </div>

            <!-- Start:: Is Active -->
            <div class="col-lg-6 py-0">
              <v-checkbox
                :label="$t('forms.labels.is_showw')"
                v-model="data.is_show"
                color="success"
              ></v-checkbox>
            </div>
            <!-- End:: Is Active -->

            <!-- START:: MAP -->
            <div class="col-lg-12">
              <div class="large-map">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="input_wrapper top_label">
                      <input
                        type="number"
                        step="any"
                        class="form-control"
                        id="autocomplete_ar"
                        placeholder=""
                        v-model="coordinates_to_edit.lat"
                        @input="upDateMakers"
                      />
                      <!-- @input="getAddressAr"
                    v-model="coordinates_to_edit.address" -->
                      <label class="form-label">{{ $t("lat") }}</label>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="input_wrapper top_label">
                      <input
                        type="number"
                        step="any"
                        class="form-control"
                        id="autocomplete_ar"
                        placeholder=""
                        v-model="coordinates_to_edit.lng"
                        @input="upDateMakers"
                      />
                      <!-- @input="getAddressAr"
                    v-model="coordinates_to_edit.address" -->
                      <label class="form-label">{{ $t("lng") }}</label>
                    </div>
                  </div>
                </div>

                <GmapMap
                  ref="gMap"
                  :center="center"
                  :zoom="12"
                  map-type-id="terrain"
                  style="width: 100%; height: 600px;"
                >
                  <GmapMarker
                    :position="coordinates_to_edit"
                    :clickable="true"
                    :draggable="true"
                    @drag="updateCoordinates"
                    @click="clickMethod(m)"
                  />
                </GmapMap>
              </div>
            </div>
            <!-- END:: MAP -->
          </div>
        </div>

        <div class="buttons_wrapper">
          <button
            :disabled="btnIsLoading"
            class="button_style_1"
            :class="btnIsLoading ? 'disabled' : ''"
          >
            {{ $t("forms.submit") }}
            <span class="btn_loader" v-if="btnIsLoading"></span>
          </button>
        </div>
      </form>
      <!-- ==== End Form ==== -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",

  data() {
    return {
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.branches.title"),
          disabled: false,
          href: "/branches/show-all",
        },
        {
          text: this.$t("breadcrumb.branches.add"),
          disabled: true,
          href: "",
        },
      ],

      // ========== Loading
      btnIsLoading: false,

      // ========== create_data
      data: {
        image: null,
        name: null,
        phone: null,
        password: null,
        password_confirmation: null,
        country: null,
        city: null,
        driver: null,
        is_active: false,
        has_pickup: false,
        has_delivery: false,
        is_check_all_drivers: false,
        is_show: false,
      },
      showCPassword: true,
      showPassword: true,
      // Fixed Data
      countries: [],
      cities: [],
      drivers: [],
      // START:: MAP
      // START:: MAP
      center: {
        lat: null,
        lng: null,
      },
      markers: [],
      coordinates_to_edit: { lat: 0, lng: 0, address: null },
      // END:: MAP
    };
  },

  mounted() {
    this.getDataLocalStorage();
  },

  methods: {
    // START:: UPDATE LOCATION
    updateCoordinates(location) {
      // this.coordinates_to_edit = {
      //   lat: location.latLng.lat(),
      //   lng: location.latLng.lng(),
      // };
      this.coordinates_to_edit.lat = location.latLng.lat();
      this.coordinates_to_edit.lng = location.latLng.lng();
    },

    clickMethod(m) {
      this.center = m.position;
    },
    // END:: UPDATE LOCATION

    // ============== Get User Location
    upDateMakers() {
      this.coordinates_to_edit = {
        lat: +this.coordinates_to_edit.lat,
        lng: +this.coordinates_to_edit.lng,
      };
      this.center = this.coordinates_to_edit;
      // if (this.$refs.gMap.map && this.markers) {
      //   this.$refs.gMap.map.setCenter(this.coordinates_to_edit);
      //   this.$refs.gMap.markers[0].setPosition(this.mapCenter);
      //   //   //   this.$refs.gMarker.map.setPosition(this.mapCenter)
      // }
      // if (this.$refs.gMap.map && this.markers) {
      //   this.$refs.gMap.markers[0].setPosition(this.es_to_edit);
      // }
    },
    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.markers = [
            {
              position: {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              },
            },
          ];
          this.center = this.markers[0].position;
          this.coordinates_to_edit.lat = this.center.lat;
          this.coordinates_to_edit.lng = this.center.lng;
        });
      }
    },

    // START:: G-MAP GET ADDRESS
    getAddressAr() {
      var self = this;
      var input = document.getElementById("autocomplete_ar");
      var searchBox = new google.maps.places.SearchBox(input);
      searchBox.addListener("places_changed", function () {
        var places = searchBox.getPlaces();
        if (places.length == 0) {
          return;
        }
        var bounds = new google.maps.LatLngBounds();
        places.forEach(function (place) {
          bounds.extend(place.geometry.location);
          place.geometry.location.lat();
          place.geometry.location.lng();
          place.formatted_address;
          self.coordinates_to_edit.address = place.formatted_address;
          self.center.lat = place.geometry.location.lat();
          self.center.lng = place.geometry.location.lng();
          self.coordinates_to_edit.lat = place.geometry.location.lat();
          self.coordinates_to_edit.lng = place.geometry.location.lng();
          self.markers[0].position.lat = place.geometry.location.lat();
          self.markers[0].position.lng = place.geometry.location.lat();
        });
      });
    },

    // START:: G-MAP GET ADDRESS
    addDataLocalStorage() {
      localStorage.setItem("branches_data", JSON.stringify(this.data));
    },

    getDataLocalStorage() {
      if (localStorage.getItem("branches_data")) {
        this.data = JSON.parse(localStorage.getItem("branches_data"));
      }
    },

    // Uplode Image
    uplodeImg_1(obj) {
      this.data.image = obj;
    },

    // Validate Data
    validateCreateForm() {
      this.btnIsLoading = true;
      if (!this.data.image) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.image"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.name) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.name"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.phone) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.phone"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.country) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.country"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.city) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.city"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.password) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.password"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (this.data.password_confirmation != this.data.password) {
        this.$iziToast.error({
          timeout: 2000,
          message: "غير مطابق لكلمه المرور",
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.is_check_all_drivers && !this.data.driver) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.driver"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else {
        this.submitData();
        return;
      }
    },

    // Submit Data
    submitData() {
      const submit_data = new FormData();
      submit_data.append("full_name", this.data.name);
      submit_data.append("phone", this.data.phone);
      submit_data.append("country_id", this.data.country?.id);
      submit_data.append("city_id", this.data.city?.id);
      // submit_data.append("country_id", this.data.country.id);
      submit_data.append("avatar", this.data.image);
      submit_data.append("is_active", +this.data.is_active);
      submit_data.append("is_show", +this.data.is_show);
      submit_data.append(
        "is_check_all_drivers",
        +this.data.is_check_all_drivers
      );
      if (!this.data.is_check_all_drivers) {
        this.data.driver.map((el, i) =>
          submit_data.append(`branche_drivers[${i}]`, el.id)
        );
      }
      if (this.data.is_active) {
        submit_data.append("has_delivery", +this.data.has_delivery);
        submit_data.append("has_pickup", +this.data.has_pickup);
      }
      submit_data.append("password", this.data.password);

      submit_data.append("lat", this.coordinates_to_edit.lat);
      submit_data.append("password", "111111");
      submit_data.append("lng", this.coordinates_to_edit.lng);
      submit_data.append(
        "location_description",
        this.coordinates_to_edit.address
      );
      submit_data.append("password", this.data.password);
      this.$axios({
        method: "POST",
        url: "branches",
        data: submit_data,
      })
        .then(() => {
          // =============== Start:: Remove Form Data From LocalStorage =============== //
          localStorage.removeItem("branches_data");

          // =============== End:: Remove Form Data From LocalStorage =============== //
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("editSuccess"),
            position: "bottomRight",
          });
          this.$router.push({ path: "/branches/show-all" });
          this.btnIsLoading = false;
        })
        .catch((err) => {
          this.btnIsLoading = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "bottomRight",
          });
        });
    },

    // Get Data
    getData() {
      this.$axios({
        method: "GET",
        url: `countries_without_pagination`,
      }).then((res) => {
        this.countries = res.data.data.map((item) => {
          return {
            id: item.id,
            name: item.name,
          };
        });
      });
    },
    getDrivers() {
      this.$axios({
        method: "GET",
        url: `get_all_driver`,
      }).then((res) => {
        this.drivers = res.data.data.map((item) => {
          return {
            id: item.id,
            name: item.full_name,
          };
        });
      });
    },
    getCities() {
      this.$axios({
        method: "GET",
        url: `country/${this.data.country?.id}/cities`,
      }).then((res) => {
        this.cities = res.data.data.map((item) => {
          return {
            id: item.id,
            name: item.name,
          };
        });
      });
    },
  },

  created() {
    this.getData();
    this.getDrivers();
    this.getLocation();
  },
};
</script>
