<template>
  <div class="wrraper">
    <!-- Start Breadcrumb -->
    <Breadcrumb
      :items="items"
      search_route="/products/add"
      :search_title="$t('addNew')"
      icon="fa-plus"
    />
    <!-- End Breadcrumb -->
    <main-filter @getResult="setRows" :code="true" />

    <!-- <MainLoader v-if="loading"></MainLoader> -->
    <div class="fadeIn">
      <!-- Start Main Section -->
      <main>
        <v-data-table
          class="elevation-1 thumb diff_table"
          :headers="headers"
          :items="rows"
          :search="search"
          :loading="loading"
          :loading-text="$t('table.loadingData')"
          item-key="id"
          :items-per-page="paginations.items_per_page"
          :show-select="true"
          v-model="selected"
          hide-default-footer
        >
          <!-- ================== You Can use any slots you want ================== -->
          <!-- Select no data State -->

          <template v-slot:no-data>
            {{ $t("table.noData") }}
          </template>

          <template v-slot:[`item.index`]="{ index }">
            {{ index + 1 }}
          </template>

          <!-- Start:: Users Routes -->
          <template v-slot:[`item.media`]="{ item }">
            <img
              v-if="item.media.length"
              :src="item.media[0].image"
              :alt="item.name"
              class="image"
              @click="show_model_1"
            />
            <img
              v-else-if="item.product_details"
              :src="item.product_details[0].media[0].image"
              :alt="item.name"
              class="image"
              @click="show_model_1"
            />
            <span v-else>{{ $t("notFound") }}</span>
          </template>
          <template v-slot:[`item.desc`]="{ item }">
            <div
              class="item-with-img d-flex justify-content-center align-items-center"
            >
              <!-- <p>
                {{ item.desc ? item.desc.slice(0, 40) : '' }}
              </p> -->

              <v-icon
                class="show"
                v-if="item.desc"
                small
                @click="showDesc(item)"
              >
                fal fa-eye
              </v-icon>
            </div>
          </template>
          <template v-slot:[`item.is_active`]="{ item }">
            <span
              class="status"
              :class="item.is_active ? 'success' : 'canceled'"
            >
              {{ item.is_active ? $t("active") : $t("inactive") }}
            </span>
          </template>

          <!-- Start:: Report Reason Modal Button -->
          <template v-slot:[`item.report_reason`]="{ item }">
            <v-icon class="show" small v-if="item.report_reason">
              fal fa-eye
            </v-icon>

            <span v-else>_</span>
          </template>
          <!-- End:: Report Reason Modal Button -->

          <!-- Select actions-->
          <template v-slot:[`item.actions`]="{ item }">
            <div class="_actions d-flex">
              <v-icon class="show" small @click="showItem(item)">
                fal fa-eye
              </v-icon>
              <v-icon class="show" small @click="editItem(item)">
                fal fa-edit
              </v-icon>
              <!-- <v-icon class="edit" small @click="editItem(item)">
                  fal fa-edit
                </v-icon> -->
              <v-icon class="delete" small @click="deleteItem(item)">
                fal fa-trash
              </v-icon>
            </div>
          </template>

          <!-- ======================== Start Top Section ======================== -->
          <template v-slot:top>
            <div class="d-flex flex-column">
              <h3 class="table-title title">
                {{ $t("breadcrumb.products.title") }}
                ({{ statisticsItem.number }})
              </h3>
              <!-- Delete dialog -->
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5 justify-center">
                    {{ $t("table.deletedialog.areYouSure") }}
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#1B5E20" @click="deleteItemConfirm">
                      {{ $t("table.deletedialog.ok") }}
                    </v-btn>
                    <v-btn color="#F44336" @click="dialogDelete = false">
                      {{ $t("table.deletedialog.cancel") }}
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete_selected" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5 justify-center">
                    {{ $t("table.deletedialog.areYouSure") }}
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#1B5E20" @click="deleteSelectedConfirm">
                      {{ $t("table.deletedialog.ok") }}
                    </v-btn>
                    <v-btn
                      color="#F44336"
                      @click="dialogDelete_selected = false"
                    >
                      {{ $t("table.deletedialog.cancel") }}
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <div class="px-3 py-3 frm-action d-flex flex-wrap flex-row gap-2">
                <button
                  v-if="selected.length > 0"
                  type="button"
                  class="button_delete ml-5"
                  @click="deleteSelected"
                >
                  <i class="far fa-trash-alt"></i>
                  &nbsp;
                  {{ $t("table.deleteSelected") }}
                </button>
                <button
                  class="export btn"
                  type="button"
                  :disabled="loadingXml"
                  @click="generateAndDownloadXML"
                >
                  <v-progress-circular
                    v-if="loadingXml"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  <span v-else>
                    Download XML <i class="fal fa-download"></i>
                  </span>
                </button>

                <!-- <a
                  v-if="downloadLink"
                  class="export btn"
                  :href="downloadLink"
                  download="products.xml"
                  >Download XML <i class="fal fa-download"></i
                ></a> -->
                <a
                  class="export btn"
                  href="https://shams-pharmacis.products.aait-d.com/api/dashboard/products-export"
                  download="true"
                >
                  <span> Download Sheet <i class="fal fa-download"></i></span>
                </a>

                <button class="export btn" @click="importModel = true">
                  <span> Import <i class="fal fa-upload"></i></span>
                </button>
                <button class="export btn">
                  <export-excel
                    :fetch="exportFields"
                    :meta="json_meta"
                    :name="$t(`titles.products`)"
                    :worksheet="$t(`titles.products`)"
                  >
                    Export <i class="fal fa-download"></i
                  ></export-excel>
                </button>
                <a
                  class="export btn"
                  href="https://shams-pharmacis.products.aait-d.com/api/dashboard/products-price-export"
                  download="true"
                >
                  <span>
                    Download Sheet (code + price)
                    <i class="fal fa-download"></i
                  ></span>
                </a>
                <button class="export btn" @click="importModelCodePrice = true">
                  <span>
                    Import (code + price) <i class="fal fa-upload"></i
                  ></span>
                </button>
                <button class="export btn">
                  <export-excel
                    :fetch="exportFieldsCodePrice"
                    :meta="json_meta"
                    :name="$t(`titles.products`)"
                    :worksheet="$t(`titles.products`)"
                  >
                    Export (code + price) <i class="fal fa-download"></i
                  ></export-excel>
                </button>
              </div>
            </div>
          </template>
          <!-- ======================== End Top Section ======================== -->
        </v-data-table>
      </main>

      <!-- End Statistics Card-->

      <!-- Start Pagination -->
      <template>
        <div
          class="pagination_container text-center mb-5 d-flex justify-content-end"
        >
          <!-- <div class="select-pagination d-flex">
            <span class="first">{{ $t('show') }}</span>
            <v-select
              :items="[5, 20, 50, 100]"
              v-model="paginations.items_per_page"
            ></v-select>
            <span>{{ $t('entries') }}</span>
          </div> -->

          <v-pagination
            color="primary"
            v-model="paginations.current_page"
            :length="paginations.last_page"
            :total-visible="7"
            @input="fetchData($event)"
          ></v-pagination>
        </div>
      </template>
      <!-- End Pagination -->
    </div>
    <!-- Start Image_Model -->
    <base-model
      @closeModel="model_1.show_model = false"
      :show="model_1.show_model"
    >
      <div class="image">
        <img
          v-if="model_1.model_img_src"
          :src="model_1.model_img_src"
          alt="..."
        />
      </div>
    </base-model>
    <base-model
      @closeModel="model_2.show_model = false"
      :show="model_2.show_model"
    >
      <div class="custom_card diff">
        <h4>وصف المنتج</h4>
        <div v-html="model_2.desc"></div>
      </div>
    </base-model>
    <v-dialog v-model="importModel" width="500">
      <v-card>
        <!-- START:: FILE INPUT -->
        <v-card-text>
          <div class="input_wrapper top_label file_input">
            <span class="file_input_label"> تحميل الملف </span>
            <label
              for="file_input_1"
              class="form-label"
              v-if="!selectedFile.file"
            ></label>
            <label for="file_input_1" class="form-label" v-else>
              {{ selectedFile.name }}
            </label>
            <input
              type="file"
              class="form-control"
              id="file_input_1"
              @change="handelSelectedFile"
            />
          </div>

          <!-- END:: FILE INPUT -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#1B5E20"
            @click="importFileConfirm"
            :loading="importLoading"
          >
            {{ $t("table.deletedialog.ok") }}
          </v-btn>
          <v-btn color="#F44336" @click="importModel = false">
            {{ $t("table.deletedialog.cancel") }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="importModelCodePrice" width="500">
      <v-card>
        <!-- START:: FILE INPUT -->
        <v-card-text>
          <div class="input_wrapper top_label file_input">
            <span class="file_input_label"> تحميل الملف </span>

            <label
              for="file_input_1"
              class="form-label"
              v-if="!selectedFile.file"
            ></label>
            <label for="file_input_1" class="form-label" v-else>
              {{ selectedFile.name }}
            </label>
            <input
              type="file"
              class="form-control"
              id="file_input_1"
              @change="handelSelectedFile"
            />
          </div>

          <!-- END:: FILE INPUT -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#1B5E20"
            @click="importFileCodePirceConfirm"
            :loading="importCodePriceLoading"
          >
            {{ $t("table.deletedialog.ok") }}
          </v-btn>
          <v-btn color="#F44336" @click="importModelCodePrice = false">
            {{ $t("table.deletedialog.cancel") }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MainFilter from "../../../components/Filters/MainFilter.vue";
import axios from "axios";
export default {
  components: { MainFilter },
  data() {
    return {
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.products.title"),
          disabled: false,
          href: "/products/show-all",
        },
        {
          text: this.$t("breadcrumb.products.all"),
          disabled: true,
          href: "",
        },
      ],

      // ========== Statistics
      statisticsItem: {},

      // ========== Top Section
      search: "",
      xmlContent: null,

      // ========== dialog Status
      dialogDelete: false,
      loadingXml: false,
      dialogDelete_selected: false,
      itemtoDelete: null,

      // ========== Model
      model_1: {
        show_model: false,
        model_img_src: "",
      },
      model_2: {
        show_model: false,
        desc: "",
      },

      // ========== Your Data
      rows: [], // injected in created

      // ========== elected Data
      selected: [],
      singleSelect: false,

      // ========== pagination
      paginations: {
        current_page: 1,
        last_page: 1,
        items_per_page: 15,
      },
      selectedFile: {
        file: null,
        name: null,
        path: null,
      },
      showItems: [
        {
          id: 1,
          value: 5,
        },
        {
          id: 2,
          value: 10,
        },
        {
          id: 1,
          value: 20,
        },
      ],
      downloadLink: null,
      // ========== Loding
      loading: false,
      importModel: false,
      importLoading: false,
      importModelCodePrice: false,
      importCodePriceLoading: false,
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      request: null,
      uploadPercentage: 0,
      uploadFinshed: false,
    };
  },

  computed: {
    ...mapGetters({
      lang: "lang_module/lang",
    }),

    headers() {
      if (this.lang == "ar") {
        return [
          {
            text: "#",
            align: "center",
            value: "id",
            sortable: true,
          },
          {
            text: "صورة المنتج",
            align: "center",
            value: "media",
            sortable: true,
          },
          {
            text: "الاسم",
            align: "center",
            value: "name",
            sortable: true,
          },

          {
            text: "ترتيب العرض",
            align: "center",
            value: "ordering",
            sortable: true,
          },
          {
            text: "الوصف",
            align: "center",
            value: "desc",
            sortable: true,
          },
          {
            text: "الكود",
            align: "center",
            value: "code",
            sortable: true,
          },
          {
            text: "الحالة",
            align: "center",
            value: "is_active",
            sortable: true,
          },
          {
            text: "الاجراءات",
            align: "center",
            value: "actions",
            sortable: true,
          },
        ];
      } else {
        return [
          {
            text: "#",
            align: "center",
            value: "id",
            sortable: true,
          },
          {
            text: "Product Image",
            align: "center",
            value: "media",
            sortable: true,
          },
          {
            text: "Name",
            align: "center",
            value: "name",
            sortable: true,
          },

          {
            text: "Ordering",
            align: "center",
            value: "ordering",
            sortable: true,
          },
          {
            text: "Description",
            align: "center",
            value: "desc",
            sortable: true,
          },
          {
            text: "Code",
            align: "center",
            value: "code",
            sortable: true,
          },
          {
            text: "Status",
            align: "center",
            value: "is_active",
            sortable: true,
          },
          {
            text: "Actions",
            align: "center",
            value: "actions",
            sortable: true,
          },
        ];
      }
    },
  },

  methods: {
    async exportFields() {
      const data = await this.$axios({
        method: "GET",
        url: "get_all_product",
      });
      let newData = [];
      data.data.data.forEach((el) => {
        let newObject = {};
        newObject[`${this.$t("forms.labels.image")}`] = el?.media.length
          ? el.media[0].image
          : this.$t("notFound");
        newObject[`${this.$t("forms.labels.name")}`] = el.name;
        newObject[`${this.$t("forms.labels.ordering")}`] = el.ordering;
        newObject[`${this.$t("forms.labels.desc")}`] = el.desc;
        newObject[`${this.$t("forms.labels.code")}`] = el.code;
        newObject[`${this.$t("forms.labels.status")}`] = el.is_active
          ? this.$t("active")
          : this.$t("inactive");
        // newObject[`${this.$t("forms.labels.city")}`] = el.city;
        newData.push(newObject);
      });
      return newData;
    },
    async exportFieldsCodePrice() {
      const data = await this.$axios({
        method: "GET",
        url: "get_all_product",
      });
      let newData = [];
      data.data.data.forEach((el) => {
        let newObject = {};

        newObject[`${this.$t("forms.labels.code")}`] = el.code;
        newObject[`${this.$t("forms.labels.price_before")}`] = el.price;
        newObject[`${this.$t("forms.labels.price_after")}`] =
          el.price_after || "";
        newData.push(newObject);
      });
      return newData;
    },
    // exportProducts() {
    //   this.$axios.get("products-export");
    // },

    importFileConfirm() {
      if (!this.selectedFile.file) {
        this.$iziToast.error({
          timeout: 2000,
          message: "يرجي اختيار الملف اولا ",
          position: "bottomRight",
        });
        return;
      } else {
        this.importLoading = true;
        const frmData = new FormData();
        frmData.append("products_file", this.selectedFile.file);
        this.$axios
          .post("products-import", frmData)
          .then(() => {
            this.importModel = false;
            this.importLoading = false;
            this.$iziToast.success({
              timeout: 2000,
              message: "تم التحميل بنجاح",
              position: "bottomRight",
            });
            this.selectedFile = {
              file: null,
              name: null,
              path: null,
            };
            this.setRows();
          })
          .catch((err) => {
            // this.importModel = false;
            this.importLoading = false;
            this.$iziToast.error({
              timeout: 2000,
              message: err.response.data.message,
              position: "bottomRight",
            });
          });
      }
    },
    importFileCodePirceConfirm() {
      if (!this.selectedFile.file) {
        this.$iziToast.error({
          timeout: 2000,
          message: "يرجي اختيار الملف اولا ",
          position: "bottomRight",
        });
        return;
      } else {
        this.importCodePriceLoading = true;
        const frmData = new FormData();
        frmData.append("product_price_file", this.selectedFile.file);
        this.$axios
          .post("products-price-import", frmData)
          .then(() => {
            this.importModelCodePrice = false;
            this.importCodePriceLoading = false;
            this.$iziToast.success({
              timeout: 2000,
              message: "تم التحميل بنجاح",
              position: "bottomRight",
            });
            this.selectedFile = {
              file: null,
              name: null,
              path: null,
            };
            this.setRows();
          })
          .catch((err) => {
            // this.importModel = false;
            this.importCodePriceLoading = false;
            this.$iziToast.error({
              timeout: 2000,
              message: err.response.data.message,
              position: "bottomRight",
            });
          });
      }
    },
    // ===== Search Method =====
    filterClick(word) {
      if (!this.loading) {
        this.search = word;
        this.helper_filterSearch();
      }
    },

    // img Model
    show_model_1(e) {
      this.model_1.model_img_src = e.target.src;
      this.model_1.show_model = true;
    },
    showDesc(e) {
      console.log(e);
      this.model_2.desc = e.desc;
      this.model_2.show_model = true;
    },

    // ==================== Start CRUD ====================
    addItem() {
      this.$router.push({ path: "/products/add" });
    },
    showItem(item) {
      this.$router.push({ path: "/products/show/" + item.id });
    },
    editItem(item) {
      this.$router.push({ path: "/products/edit/" + item.id });
    },

    // ===== Delete
    deleteItem(item) {
      this.dialogDelete = true;
      this.itemtoDelete = item;
    },
    deleteItemConfirm() {
      this.$axios({
        method: "DELETE",
        url: `products/${this.itemtoDelete.id}`,
      })
        .then(() => {
          this.rows = this.rows.filter((item) => {
            return item.id != this.itemtoDelete.id;
          });
          this.dialogDelete = false;
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("deleteSuccess"),
            position: "bottomRight",
          });
        })
        .catch((err) => {
          this.dialogDelete = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "bottomRight",
          });
        });
    },
    deleteSelected() {
      this.dialogDelete_selected = true;
    },
    deleteSelectedConfirm() {
      let submit_data = new FormData();
      this.selected.forEach((item, index) => {
        submit_data.append(`productIds[${index}]`, item.id);
      });
      this.$axios({
        method: "POST",
        url: `delete_multi_products`,
        // data: { ids: this.selected.map((item) => item.id) },
        data: submit_data,
      })
        .then(() => {
          this.rows = this.rows.filter((item) => {
            return !this.selected.filter((obj) => obj.id == item.id).length > 0;
          });
          this.dialogDelete_selected = false;
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("deleteSuccess"),
            position: "bottomRight",
          });
        })
        .catch((err) => {
          this.dialogDelete_selected = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "bottomRight",
          });
        });
    },
    // ==================== End CRUD ====================
    handelSelectedFile(e) {
      this.selectedFile.file = e.target.files[0];
      this.selectedFile.name = e.target.files[0].name;
      this.selectedFile.path = URL.createObjectURL(e.target.files[0]);
    },
    // Set Rows
    setRows(e) {
      this.loading = true;
      if (e) {
        console.log(e);
        // this.paginations.current_page = 1;
      }
      const params = new URLSearchParams();
      params.append("page", this.paginations.current_page);
      params.append("keyword", e?.searchInput ? e.searchInput : "");
      params.append("code", e?.code ? e.code : "");
      // params.append('from_price', e?.start_price ? e.start_price : '')
      // params.append('to_price', e?.end_price ? e.end_price : '')
      // params.append(
      //   'category_id',
      //   e?.selectedCategory ? e.selectedCategory.id : '',
      // )
      // params.append('size', e?.size ? e.size.id : '')
      // params.append('color', e?.color ? e.color.id : '')

      // if (e?.feature) {
      //   e.feature.map((el, index) => {
      //     params.append(`features[${index}]`, el.id)
      //   })
      // }
      this.$axios({
        method: "GET",
        url: "products",
        params: params,
      })
        .then((res) => {
          this.paginations.last_page = res.data.meta?.last_page;
          this.paginations.items_per_page = res.data.meta?.per_page;
          this.statisticsItem.number = res.data.meta.total;
          this.rows = res.data.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    generateAndDownloadXML() {
      this.downloadLink = null;
      this.loadingXml = true;

      axios
        .get("all_products_without_pagiante")
        .then((res) => {
          this.uploadFinshed = true;
          this.xmlContent = this.generateXML(res.data.data);
        })
        .catch((err) => {
          this.loadingXml = false;
        });
    },

    generateXML(products) {
      // Replace this with your XML generation logic
      const xmlProducts = products
        .map(
          (product) => `<item><g:id>${product.id}</g:id>
                    <title><![CDATA[${product.name}]]></title>
                    <description><![CDATA[${product.desc}]]></description>
                    <link>${this.$BaseWebUrl}/products/${product.id}</link>
                    <g:image_link>${product.image}</g:image_link>
                    <g:price>${product.price + " " + product.currency}</g:price>
                    <g:tax>
                        <g:country>SA</g:country>
                        <g:rate>${product.rate}</g:rate>
                        <g:tax_ship>${product.vat_percentage}</g:tax_ship>
                    </g:tax>
                    <g:shipping>
                        <g:country>SA</g:country>
                        <g:price>${
                          product.shipping + " " + product.currency
                        }</g:price>
                    </g:shipping>
                    <g:availability><![CDATA[${
                      product.availability
                    }]]></g:availability>
                    <g:google_product_category><![CDATA[${
                      product.category_name
                    }]]></g:google_product_category>
                    <g:identifier_exists>TRUE</g:identifier_exists>
                    <g:product_type><![CDATA[${product.type}]]></g:product_type>
                    <g:sale_price>${
                      product.price_after ? product.price_after : ""
                    }</g:sale_price>
                    <g:brand>${product.availability}</g:brand>
                    <g:color></g:color>
                    <g:additional_image_link><![CDATA[${
                      product.image
                    }]]></g:additional_image_link></item>`
        )
        .join("");
      const generatedXML = `<?xml version="1.0"?><rss version="2.0" xmlns:g="http://base.google.com/ns/1.0"><channel><created_at>${new Date().toLocaleString()}</created_at>
              ${xmlProducts}
           </channel></rss>`;
      const blob = new Blob([generatedXML], { type: "application/xml" });
      this.downloadLink = URL.createObjectURL(blob);
      this.loadingXml = false;
      this.downloadURI(this.downloadLink, "products.xml");
    },
    downloadURI(uri, name) {
      const link = document.createElement("a");
      link.download = name;
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    fetchData(e) {
      this.$router
        .push({
          query: Object.assign({}, this.$route.query, {
            page: e,
          }),
        })
        .catch(() => {});

      this.setRows();
    },
  },

  // ======= hooks
  created() {
    if (this.$route.query.page) {
      this.paginations.current_page = +this.$route.query.page;
    }
    this.setRows();
  },
  watch: {
    ["$route"](newVal) {
      if (newVal.query.keyword) {
        this.paginations.current_page = 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.model_container .main_body .image {
  width: 120px;
  img {
    max-height: 100%;
    // object-fit: contain;
    object-fit: contain;
    max-width: 440px;
    display: block;
    min-width: unset;
    max-width: 500px;
  }
}
.custom_card.diff {
  max-width: 600px;
  min-width: 600px;
  border: 2px solid var(--mainColor-2);
  h4 {
    font-size: 19px;
    text-align: center;
    color: var(--mainColor-2);
  }
  p {
    font-size: 15px;
    text-align: center;
    word-break: break-all;
  }
}
</style>
