export default {
  methods: {
    createRandomString() {
      const stringSpace =
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
      const stringLength = stringSpace.length;
      let randomString = "";
      for (let i = 0; i < 12; i++) {
        randomString =
          randomString + stringSpace[Math.floor(Math.random() * stringLength)];
      }
      return randomString;
    },
    helper_checkIfInputIsEmpty(e) {
      let inputElement = e.target;
      if (inputElement.value.length > 0) {
        inputElement.classList.remove("empty");
      } else {
        inputElement.classList.add("empty");
      }
    },

    helper_togglePasswordVisibility(e) {
      let passwordElement = e.currentTarget.parentElement.children[1];
      let passwordTogglerBtn = e.currentTarget;
      if (passwordElement.type == "password") {
        passwordElement.type = "text";
        passwordTogglerBtn.classList.add("password_is_visible");
      } else if (passwordElement.type == "text") {
        passwordElement.type = "password";
        passwordTogglerBtn.classList.remove("password_is_visible");
      }
    },

    helper_validEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    helper_filterSearch(value, search, item) {
      console.log(item);
      if (search == "all") return true;
      return value != null && search != null && value.indexOf(search) !== -1;
    },

    helper_containsObject(obj, arr, key) {
      console.log(arr);
      console.log(key);
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].uniqeID == obj.uniqeID) {
          console.log("the same element");
        } else {
          console.log("not the same");
        }
        // if (arr[i][key] !== obj[key]) {
        //   return true;
        // }
      }
      // return false;
    },
    //English
    show_model_1(src) {
      this.model_1.model_img_src = src;
      this.model_1.show_model = true;
    },
    //English
    isEnglish(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[\u0621-\u064A]+$/.test(char)) {
        e.preventDefault();
        this.$iziToast.error({
          timeout: 2000,
          displayMode: 2,
          message: this.$t("validation.appLangEn"),
          position: "bottomRight",
        });
      }
    },
    isArabic(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z]+$/.test(char)) {
        e.preventDefault();
        this.$iziToast.error({
          timeout: 2000,
          displayMode: 2,
          message: this.$t("forms.validation.appLangAr"),
          position: "bottomRight",
        });
      }
    },
    isEmailValid(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[\u0621-\u064A]+$/.test(char)) {
        e.preventDefault();
        this.$iziToast.error({
          timeout: 2000,
          displayMode: 2,
          message: this.$t("forms.validation.appLangEn"),
          position: "bottomRight",
        });
      } else {
        return true;
      }
    },
    preventMinus(e) {
      let inputElement = e.target;
      if (!inputElement.checkValidity()) {
        inputElement.value = "";
      }
    },
  },
  computed: {
    lang_global() {
      return this.$store.getters["lang_module/lang"];
    },
  },
};
