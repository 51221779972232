<template>
  <div class="create_wrapper fadeIn">
    <!-- Start Breadcrumb -->
    <Breadcrumb :items="items" />
    <!-- End Breadcrumb -->

    <div class="custom_card">
      <div class="card-header">
        <h4 class="card-title">{{ $t("addNew") }}</h4>
      </div>

      <!-- ==== Start Form ==== -->
      <form @submit.prevent="validateCreateForm">
        <div class="container">
          <div class="row justify-content-between">
            <!-- Image -->

            <uplode-image
              class="col-lg-6"
              @inputChanged="uplodeImg_1"
              p:placeHolder="$t('forms.labels.image')"
            ></uplode-image>
            <uplode-image
              class="col-lg-6"
              @inputChanged="uplodeImg_2"
              p:placeHolder="$t('forms.labels.image')"
            ></uplode-image>
            <!-- Start:: Is Active -->
            <div class="col-lg-12 py-0">
              <v-checkbox
                :label="$t('forms.labels.is_active')"
                v-model="data.is_active"
                color="success"
              ></v-checkbox>
            </div>
            <!-- End:: Is Active -->

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.ar.title"
                  @keypress="isArabic($event)"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.title_ar") }}
                </label>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.en.title"
                  @keypress="isEnglish($event)"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.title_en") }}
                </label>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.ar.slug"
                  @keypress="isArabic($event)"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.slug_ar") }}
                </label>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.en.slug"
                  @keypress="isEnglish($event)"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.slug_en") }}
                </label>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <label for="textarea_1" class="form-label">
                  {{ $t("forms.labels.desc_ar") }}
                </label>
                <textarea
                  class="form-control"
                  rows="5"
                  v-model="data.ar.desc"
                  @keypress="isArabic($event)"
                ></textarea>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <label for="textarea_1" class="form-label">
                  {{ $t("forms.labels.desc_en") }}
                </label>
                <textarea
                  class="form-control"
                  rows="5"
                  v-model="data.en.desc"
                  @keypress="isEnglish($event)"
                ></textarea>
              </div>
            </div>
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <label for="textarea_1" class="form-label">
                  {{ $t("forms.labels.typeOfDelivery") }}
                </label>
                <multiselect
                  v-model="data.delivery_duration_type"
                  :options="durationTypes"
                  label="name"
                  track-by="id"
                  placeholder=" "
                  :searchable="true"
                  :allow-empty="true"
                  :show-labels="false"
                ></multiselect>
              </div>
            </div>
            <!-- <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <label for="textarea_1" class="form-label">
                  {{ $t("forms.labels.deliveryTime") }}
                </label>
                <input
                  type="number"
                  @keypress="preventMinus"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model="data.delivery_duration"
                />
              </div>
            </div> -->
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <label for="textarea_1" class="form-label">
                  {{ $t("forms.labels.ordering") }}
                </label>
                <input
                  type="number"
                  @keypress="preventMinus"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model="data.ordering"
                />
              </div>
            </div>

            <div class="col-lg-6">
              <v-radio-group v-model="data.type" row>
                <v-radio
                  :label="$t('forms.labels.main_category')"
                  value="main"
                ></v-radio>
                <v-radio
                  :label="$t('forms.labels.subCategory')"
                  @click="getCategories"
                  value="sub"
                ></v-radio>
              </v-radio-group>
            </div>
            <div class="col-lg-6">
              <v-checkbox
                v-if="data.type == 'sub'"
                :label="$t('forms.labels.is_show')"
                v-model="data.is_show"
                color="success"
              ></v-checkbox>
            </div>

            <div class="col-lg-6 py-0" v-if="data.type == 'sub'">
              <div class="input_wrapper top_label">
                <label class="form-label">
                  {{ $t("forms.labels.mainCategory") }}
                </label>
                <treeselect
                  dir="rtl"
                  :multiple="false"
                  placeholder=""
                  :options="categories"
                  :show-count="true"
                  :flat="false"
                  v-model="data.category"
                />
                <!-- <multiselect
                  v-model="data.category"
                  :options="categories"
                  label="name"
                  track-by="id"
                  placeholder=" "
                  :searchable="true"
                  :allow-empty="true"
                  :show-labels="false"
                ></multiselect> -->
              </div>
            </div>
          </div>
        </div>

        <div class="buttons_wrapper">
          <button class="button_style_1" :disabled="btnIsLoading">
            {{ $t("forms.submit") }}
            <span class="btn_loader" v-if="btnIsLoading"></span>
          </button>
        </div>
      </form>
      <!-- ==== End Form ==== -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",
  props: ["id"],

  data() {
    return {
      loading: false,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.categories.title"),
          disabled: false,
          href: "/categories/show-all",
        },
        {
          text: this.$t("breadcrumb.categories.add"),
          disabled: true,
          href: "",
        },
      ],

      // ========== Loading
      btnIsLoading: false,

      // ========== create_data
      data: {
        image_en: null,
        image_ar: null,
        ar: {
          title: null,
          desc: null,
          slug: null,
        },
        en: {
          title: null,
          desc: null,
          slug: null,
        },
        is_active: true,
        delivery_duration_type: null,
        delivery_duration: null,

        type: "main",
        ordering: null,
        category: null,
        is_show: null,
      },
      durationTypes: [
        // { id: "minute", name: this.$t("minute") },
        { id: "after_2_hours", name: this.$t("forms.labels.after_2_hours") },
        { id: "express", name: this.$t("forms.labels.express") },
        { id: "next_day", name: this.$t("forms.labels.next_day") },
      ],
      // Fixed Data
      categories: [],
    };
  },

  methods: {
    // Uplode Image
    uplodeImg_1(obj) {
      this.data.image_ar = obj;
    },
    uplodeImg_2(obj) {
      this.data.image_en = obj;
    },

    // Validate Data
    validateCreateForm() {
      this.btnIsLoading = true;

      if (!this.data.image_ar?.img_src || !this.data.image_en?.img_src) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.image"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.ar.title) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.title_ar"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.en.title) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.title_en"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      }
      // else if (!this.data.ar.slug) {
      //   this.$iziToast.error({
      //     timeout: 2000,
      //     message: this.$t("forms.validation.slug_ar"),
      //     position: "bottomRight",
      //   });
      //   this.btnIsLoading = false;
      //   return;
      // } else if (!this.data.en.slug) {
      //   this.$iziToast.error({
      //     timeout: 2000,
      //     message: this.$t("forms.validation.slug_en"),
      //     position: "bottomRight",
      //   });
      //   this.btnIsLoading = false;
      //   return;
      // }
      // else if (!this.data.ordering) {
      //   this.$iziToast.error({
      //     timeout: 2000,
      //     message: this.$t("forms.validation.ordering"),
      //     position: "bottomRight",
      //   });
      //   this.btnIsLoading = false;
      //   return;
      // } else if (this.data.ordering && this.data.ordering < 0) {
      //   this.$iziToast.error({
      //     timeout: 2000,
      //     message: this.$t("forms.validation.orderLength"),
      //     position: "bottomRight",
      //   });
      //   this.btnIsLoading = false;
      //   return;
      // } else if (!this.data.en.desc) {
      //   this.$iziToast.error({
      //     timeout: 2000,
      //     message: this.$t("forms.validation.content_ar"),
      //     position: "bottomRight",
      //   });
      //   this.btnIsLoading = false;
      //   return;
      // } else if (!this.data.ar.desc) {
      //   this.$iziToast.error({
      //     timeout: 2000,
      //     message: this.$t("forms.validation.content_en"),
      //     position: "bottomRight",
      //   });
      //   this.btnIsLoading = false;
      //   return;
      // }
      else if (!this.data.delivery_duration_type) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.deliveryType"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.is_active) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.is_active"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else {
        this.submitData();
        return;
      }
    },

    // Submit Data
    submitData() {
      this.btnIsLoading = true;
      if (this.data.type == "sub" && !this.data.category) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.mainCat"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;

        return;
      } else {
        const submit_data = new FormData();
        submit_data.append("image_ar", this.data.image_ar.img_file);
        submit_data.append("image", this.data.image_en.img_file);
        submit_data.append("ar[name]", this.data.ar.title);
        submit_data.append("ar[desc]", this.data.ar.desc ?? "");
        submit_data.append("ar[slug]", this.data.ar.slug ?? "");
        submit_data.append("en[name]", this.data.en.title);
        submit_data.append("en[desc]", this.data.en.desc ?? "");
        submit_data.append("en[slug]", this.data.en.slug ?? "");
        if (this.data.type == "sub") {
          submit_data.append(
            "ordering",
            this.data.ordering ? this.data.ordering : ""
          );
        }
        if (this.data.ordering) {
          submit_data.append(
            "ordering",
            this.data.ordering ? this.data.ordering : ""
          );
        }
        submit_data.append(
          "delivery_type",
          this.data.delivery_duration_type.id
        );
        // submit_data.append("delivery_duration", this.data.delivery_duration);
        submit_data.append("is_active", +this.data.is_active);

        if (this.data.type == "sub") {
          submit_data.append("parent_id", this.data.category);
        }

        this.$axios({
          method: "POST",
          url: "category",
          data: submit_data,
        })
          .then(() => {
            this.$iziToast.success({
              timeout: 2000,
              message: this.$t("addSuccess"),
              position: "bottomRight",
            });

            this.$router.push({ path: "/categories/show-all" });

            this.btnIsLoading = false;
          })
          .catch((err) => {
            this.$iziToast.error({
              timeout: 2000,
              message: err.response.data.message,
              position: "bottomRight",
            });
            this.btnIsLoading = false;
          });
      }
    },

    // ====== Main Categories
    getCategories() {
      this.$axios({
        method: "GET",
        url: `category_tree`,
      }).then((res) => {
        this.categories = res.data.data;
        if (this.id) {
          this.data.type = "sub";
          // this.data.category = res.data.data.find((el) => el.id == this.id)
        }
      });
    },
  },

  created() {
    this.getCategories();
  },
};
</script>
