import Vue from "vue";
import VueRouter from "vue-router";

// ============================== Start Views ==============================
import Home from "../views/Home.vue"; // Home

// ********** Children
import HomePage from "../views/children/HomePage.vue";

// ========== Start HomePage ==========

// ========== Start Slider ==========
import SliderHome from "../views/children/Slider/Home.vue";
// Show All
import AllSliders from "../views/children/Slider/ShowAll.vue";
// Add
import AddSlide from "../views/children/Slider/Add.vue";
// Edit
import EditSlide from "../views/children/Slider/Edit.vue";
// ========== End Slider ==========

// ========== Start Products ==========
import ProductHome from "../views/children/products/Home.vue";
// Show All
import AllProduct from "../views/children/products/ShowAll.vue";
// Add
import AddProduct from "../views/children/products/Add.vue";
// Edit
import EditProduct from "../views/children/products/Edit.vue";
// Show Product
import ShowProduct from "../views/children/products/Show.vue";
// ========== End Products ==========

// ========== Start Orders ==========
import OrdersHome from "../views/children/Orders/Home.vue";
// All Orders
import Orders from "../views/children/Orders/ShowAll.vue";
// Single Order
import ShowOrder from "../views/children/Orders/Show.vue";
// ========== End Orders ==========

// **** Start Categories
import CategoriesHome from "../views/children/Categories/Home.vue";
// Show All
import AllCategories from "../views/children/Categories/ShowAll.vue";

// Add
import AddCategory from "../views/children/Categories/Add.vue";
// Edit
import EditCategory from "../views/children/Categories/Edit.vue";
// **** End Categories

// ========== Start PROMO CODE ==========
import PromoCode from "../views/children/PromoCode/Home.vue";
// Show All
import AllPromoCode from "../views/children/PromoCode/ShowAll.vue";
// Add
import AddPromoCode from "../views/children/PromoCode/Add.vue";
// Edit
import EditPromoCode from "../views/children/PromoCode/Edit.vue";
// ========== End PROMO CODE ==========

// ========== Start Sizes ==========
import SizesHome from "../views/children/Sizes/Home.vue";
// Show All
import AllSizes from "../views/children/Sizes/ShowAll.vue";
// Add
import AddSize from "../views/children/Sizes/Add.vue";
// Edit
import EditSize from "../views/children/Sizes/Edit.vue";
// ========== End Sizes ==========

// ========== Start Feature ==========
import FeaturesHome from "../views/children/Features/Home.vue";
// Show All
import AllFeatures from "../views/children/Features/ShowAll.vue";
// Add
import AddFeature from "../views/children/Features/Add.vue";
// Edit
import EditFeature from "../views/children/Features/Edit.vue";
// ========== End Features ==========
// ========== Start Colors ==========
import ColorsHome from "../views/children/Colors/Home.vue";
// Show All
import AllColors from "../views/children/Colors/ShowAll.vue";
// Add
import AddColor from "../views/children/Colors/Add.vue";
// Edit
import EditColor from "../views/children/Colors/Edit.vue";
// ========== End Colors ==========

// ========== Start Users ==========

import UsersHome from "../views/children/Users/Home.vue";
import AllUserss from "../views/children/Users/ShowAll.vue";
import AddUsers from "../views/children/Users/Add.vue";
import EditUsers from "../views/children/Users/Edit.vue";
import UserShowHome from "../views/children/Users/UserProfile/AdminShowHome.vue";

// ========== end Users ==========

// ========== Start Countries ==========
import CountriesHome from "../views/children/Countries/Home.vue";
// Show All
import AllCountries from "../views/children/Countries/ShowAll.vue";
// Add
import AddCountry from "../views/children/Countries/Add.vue";
// Edit
import EditCountry from "../views/children/Countries/Edit.vue";
// ========== End Countries ==========

// ========== Start Cities ==========
import CitiesHome from "../views/children/Cities/Home.vue";
// Show All
import AllCities from "../views/children/Cities/ShowAll.vue";
// Add
import AddCity from "../views/children/Cities/Add.vue";
// Edit
import EditCity from "../views/children/Cities/Edit.vue";
// ========== End Cities ==========

// ========== Start Cities ==========
import BranchesHome from "../views/children/Branches/Home.vue";
// Show All
import AllBranches from "../views/children/Branches/ShowAll.vue";
// Add
import AddBranch from "../views/children/Branches/Add.vue";
// Edit
import EditBranch from "../views/children/Branches/Edit.vue";
// ========== End Cities ==========

// ========== Start WorkTime ==========
import WorkTimeHome from "../views/children/WorkTime/Home.vue";
import AllWorkTime from "../views/children/WorkTime/ShowAll.vue";
import AddWorkTime from "../views/children/WorkTime/Add.vue";
import EditWorkTime from "../views/children/WorkTime/Edit.vue";
// ========== end WorkTime ==========

// ========== Start profile ==========
import ProfileHome from "../views/children/Profile/Home.vue";
import ProfileShow from "../views/children/Profile/AdminShowHome.vue";
import ProfileEdit from "../views/children/Profile/Edit.vue";
// ********** Auth
import Login from "../views/Auth/Login.vue";

// ********** NotFound
import NotFound from "../views/NotFound.vue";

// ============================== End Views ==============================

// Vuex store
import store from "../store/index";
let router = null;
window.rootRouter = router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes: [
    // Main
    {
      path: "/",
      component: Home,
      children: [
        {
          path: "/",
          name: "HomePage",
          component: HomePage,
        },

        // ========== Start Sizes ==========
        {
          path: "/slider",
          component: SliderHome,
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllSliders",
              component: AllSliders,
            },
            // Add
            {
              path: "add",
              name: "AddSlide",
              component: AddSlide,
            },
            // Edit
            {
              path: "edit/:id",
              name: "EditSlide",
              component: EditSlide,
              props: true,
            },
          ],
        },
        // ========== End Sizes ==========

        {
          path: "/products",
          component: ProductHome,

          children: [
            // Show All
            {
              path: "show-all",
              name: "AllProducts",
              component: AllProduct,
            },
            // Add
            {
              path: "add",
              name: "AddProduct",
              component: AddProduct,
            },
            // Edit
            {
              path: "edit/:id",
              name: "EditProduct",
              component: EditProduct,
              props: true,
            },
            {
              path: "show/:id",
              name: "ShowProduct",
              component: ShowProduct,
              props: true,
            },
          ],
        },
        // ========== Start Categories ==========
        // ========== End Sizes ==========

        {
          path: "/magazine",
          component: () => import("@/views/children/magazines/Home.vue"),

          children: [
            // Show All
            {
              path: "show-all",
              name: "all-magazine",
              component: () => import("@/views/children/magazines/ShowAll.vue"),
            },
            // Add
            {
              path: "add",
              name: "add-magazine",
              component: () => import("@/views/children/magazines/Add.vue"),
            },
            // Edit
            {
              path: "edit/:id",
              name: "edit-magazine",
              component: () => import("@/views/children/magazines/Edit.vue"),
              props: true,
            },
          ],
        },
        // ========== Start Categories ==========
        {
          path: "categories",
          component: CategoriesHome,
          children: [
            {
              path: "show-all",
              name: "AllCategories",
              component: AllCategories,
            },
            {
              path: ":id/sub-category",
              name: "ShowSubCategoris",
              component: () =>
                import("../views/children/Categories/ShowSubCategoris.vue"),
              props: true,
            },
            // Add
            {
              path: "add/:id?",
              props: true,
              name: "AddCategory",
              component: AddCategory,
            },
            // Edit
            {
              path: "edit/:id",
              name: "EditCategory",
              component: EditCategory,
              props: true,
            },
          ],
        },
        // ========== End Categories ==========

        // ========== Start Promo Code ==========
        {
          path: "/promoCode",
          component: PromoCode,
          children: [
            // Show All
            {
              path: "",
              name: "AllPromoCode",
              component: AllPromoCode,
            },
            // Add
            {
              path: "add",
              name: "AddPromoCode",
              component: AddPromoCode,
            },
            // Show
            {
              path: "show/:id",
              name: "ShowPromoCode",
              component: () => import("../views/children/PromoCode/Show.vue"),
              props: true,
            },
            // Edit
            {
              path: "edit/:id",
              name: "EditPromoCode",
              component: EditPromoCode,
              props: true,
            },
          ],
        },
        // ========== End Promo Code ==========

        // ========== Start Sizes ==========
        {
          path: "/sizes",
          component: SizesHome,
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllSizes",
              component: AllSizes,
            },
            // Add
            {
              path: "add",
              name: "AddSize",
              component: AddSize,
            },
            // Edit
            {
              path: "edit/:id",
              name: "EditSize",
              component: EditSize,
              props: true,
            },
          ],
        },
        {
          path: "/brands",
          component: () => import("../views/children/brands/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllBrands",
              component: () => import("../views/children/brands/ShowAll.vue"),
            },
            // Add
            {
              path: "add",
              name: "AddBrand",
              component: () => import("../views/children/brands/Add.vue"),
            },
            // Edit
            {
              path: "edit/:id",
              name: "EditBrand",
              component: () => import("../views/children/brands/Edit.vue"),
              props: true,
            },
          ],
        },
        // offers
        {
          path: "/offers",
          component: () => import("../views/children/Offers/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllOffers",
              component: () => import("../views/children/Offers/ShowAll.vue"),
            },
            // Add
            {
              path: "add",
              name: "AddOffer",
              component: () => import("../views/children/Offers/Add.vue"),
            },
            // Edit
            {
              path: "edit/:id",
              name: "EditOffer",
              component: () => import("../views/children/Offers/Edit.vue"),
              props: true,
            },
          ],
        },

        // offers
        // Coupons
        {
          path: "coupons",
          component: () => import("../views/children/Coupons/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllCoupons",
              component: () => import("../views/children/Coupons/ShowAll.vue"),
            },
            // Add
            {
              path: "add",
              name: "AddCoupons",
              component: () => import("../views/children/Coupons/Add.vue"),
            },
            // Edit
            {
              path: "edit/:id",
              name: "EditCoupons",
              component: () => import("../views/children/Coupons/Edit.vue"),
              props: true,
            },
          ],
        },

        // Coupons
        // ========== End Sizes ==========

        // ========== Start Colors ==========
        {
          path: "/colors",
          component: ColorsHome,
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllColors",
              component: AllColors,
            },
            // Add
            {
              path: "add",
              name: "AddColor",
              component: AddColor,
            },
            // Edit
            {
              path: "edit/:id",
              name: "EditColor",
              component: EditColor,
              props: true,
            },
          ],
        },
        // ========== End Colors ==========

        // ========== Start Features ==========
        {
          path: "/features",
          component: FeaturesHome,
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllFeatures",
              component: AllFeatures,
            },
            // Add
            {
              path: "add",
              name: "AddFeature",
              component: AddFeature,
            },
            // Edit
            {
              path: "edit/:id",
              name: "EditFeature",
              component: EditFeature,
              props: true,
            },
          ],
        },
        // ========== End Products ==========

        // ========== Start Orders ==========
        {
          path: "/orders",
          component: OrdersHome,
          children: [
            // All Orders
            {
              path: "all-orders",
              name: "Orders",
              component: Orders,
            },
            {
              path: "show/:id",
              name: "ShowOrder",
              component: ShowOrder,
              props: true,
            },
          ],
        },
        // ========== End Orders ==========

        {
          path: "/all-inputs",
          name: "all-inputs",
          component: () => import("../components/Forms/InputWrapper.vue"),
        },

        // ========== Start Users ==========
        {
          path: "/users",
          component: UsersHome,
          children: [
            {
              path: "show-all",
              name: "AllUserss",
              component: AllUserss,
            },
            {
              path: "add",
              name: "AddUsers",
              component: AddUsers,
            },
            {
              path: "edit/:id",
              name: "EditUsers",
              component: EditUsers,
              props: true,
            },
            // Show Admin
            {
              path: "show/:id",
              component: UserShowHome,
              props: true,
            },
          ],
        },
        // ========== End Users ==========

        // ========== Start Drivers ==========
        {
          path: "/drivers",
          component: () => import("@/views/children/drivers/Home.vue"),
          children: [
            {
              path: "show-all",
              name: "all-drivers",
              component: () => import("@/views/children/drivers/ShowAll.vue"),
            },
            {
              path: "add",
              name: "add-driver",
              component: () => import("@/views/children/drivers/Add.vue"),
            },
            {
              path: "edit/:id",
              name: "edit-driver",
              component: () => import("@/views/children/drivers/Edit.vue"),
              props: true,
            },
            // Show Admin
            {
              path: "show/:id",
              name: "show-driver",
              component: () =>
                import(
                  "@/views/children/drivers/UserProfile/AdminShowHome.vue"
                ),
              props: true,
            },
          ],
        },
        // ========== End Drivers ==========

        // ========== Start Providers ==========
        {
          path: "/web_clients",
          component: () => import("../views/children/Providers/Home.vue"),
          children: [
            {
              path: "show-all",
              name: "AllProviders",
              component: () =>
                import("../views/children/Providers/ShowAll.vue"),
            },
            {
              path: "add",
              name: "AddProvider",
              component: () => import("../views/children/Providers/Add.vue"),
            },
            {
              path: "edit/:id",
              name: "EditProvider",
              component: () => import("../views/children/Providers/Edit.vue"),
              props: true,
            },
            // Show Admin
            {
              path: "show/:id",

              component: () =>
                import(
                  "../views/children/Providers/UserProfile/AdminShowHome.vue"
                ),
              props: true,
              children: [
                {
                  path: "",
                  name: "cards",
                  component: () =>
                    import(
                      "../views/children/Providers/UserProfile/children/Cards.vue"
                    ),
                },
                {
                  path: "data",
                  component: () =>
                    import(
                      "../views/children/Providers/UserProfile/children/PersonalData.vue"
                    ),
                },
                {
                  path: "store-data",
                  component: () =>
                    import(
                      "../views/children/Providers/UserProfile/children/StoreData.vue"
                    ),
                },
              ],
            },
          ],
        },
        // ========== End Providers ==========

        // ========== Start profile ==========
        {
          path: "/profile",
          component: ProfileHome,
          children: [
            {
              path: "show",
              component: ProfileShow,
            },
            {
              path: "edit",
              component: ProfileEdit,
            },
          ],
        },
        // ========== End profile ==========
        // ========== Start Countries ==========
        {
          path: "/countries",
          component: CountriesHome,
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllCountries",
              component: AllCountries,
            },
            // Add
            {
              path: "add",
              name: "AddCountry",
              component: AddCountry,
            },
            // Edit
            {
              path: "edit/:id",
              name: "EditCountry",
              component: EditCountry,
              props: true,
            },
          ],
        },
        // ========== End Countries ==========

        // ========== Start Cities ==========
        {
          path: "/cities",
          component: CitiesHome,
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllCities",
              component: AllCities,
            },
            // Add
            {
              path: "add",
              name: "AddCity",
              component: AddCity,
            },
            // Edit
            {
              path: "edit/:id",
              name: "EditCitiy",
              component: EditCity,
              props: true,
            },
          ],
        },
        // ========== End Cities ==========

        // ========== Start Branches ==========
        {
          path: "/branches",
          component: BranchesHome,
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllBranches",
              component: AllBranches,
            },
            // Add
            {
              path: "add",
              name: "AddBranch",
              component: AddBranch,
            },
            // Edit
            {
              path: "edit/:id",
              name: "EditBranche",
              component: EditBranch,
              props: true,
            },
          ],
        },
        // ========== End Branches ==========
        // ========== Start District ==========
        {
          path: "/districts",
          component: () => import("../views/children/District/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllDistricts",
              component: () => import("../views/children/District/ShowAll.vue"),
            },
            // Add
            {
              path: "add",
              name: "AddDistrict",
              component: () => import("../views/children/District/Add.vue"),
            },
            // Edit
            {
              path: "edit/:id",
              name: "EditDistrict",
              component: () => import("../views/children/District/Edit.vue"),
              props: true,
            },
          ],
        },
        // ========== End District ==========
        // ========== Start reject-reasons ==========
        {
          path: "/reject-reasons",
          component: () => import("../views/children/RejectReasons/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllRejectReasonss",
              component: () =>
                import("../views/children/RejectReasons/ShowAll.vue"),
            },
            // Add
            {
              path: "add",
              name: "AddRejectReasons",
              component: () =>
                import("../views/children/RejectReasons/Add.vue"),
            },
            // Edit
            {
              path: "edit/:id",
              name: "EditRejectReasons",
              component: () =>
                import("../views/children/RejectReasons/Edit.vue"),
              props: true,
            },
          ],
        },
        // ========== End reject-reasons ==========

        // ========== Start wallets ==========
        {
          path: "/work-time",
          component: WorkTimeHome,
          children: [
            {
              path: "show-all",
              name: "AllWorkTime",
              component: AllWorkTime,
            },
            {
              path: "add",
              name: "AddWorkTime",
              component: AddWorkTime,
            },
            {
              path: "edit/:id?",
              component: EditWorkTime,
              props: true,
            },
          ],
        },
        // ========== End wallets ==========
        {
          path: "/notifications",
          component: () => import("../views/children/Notifications/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllNotifications",
              component: () =>
                import("../views/children/Notifications/ShowAll.vue"),
            },
            // Add
            {
              path: "add",
              name: "AddNotification",
              component: () =>
                import("../views/children/Notifications/Add.vue"),
            },
            // Edit
            {
              path: "show/:id",
              name: "showNotification",
              component: () =>
                import("../views/children/Notifications/Show.vue"),
              props: true,
            },
          ],
        },
        // ========== Start Settings ==========
        {
          alias: "/settings",
          path: "settings",
          component: () => import("../views/children/Settings/Global/Home.vue"),
          children: [
            {
              path: "/",
              name: "setting",
              component: () =>
                import("../views/children/Settings/Global/Show.vue"),
            },
            {
              path: "update",
              name: "update_settings",
              component: () =>
                import("../views/children/Settings/Global/index.vue"),
            },
          ],
        },
        {
          alias: "/about",
          path: "about",
          component: () => import("../views/children/Settings/About/Home.vue"),
          children: [
            // Show
            {
              path: "show-all",
              name: "aboutShow",
              component: () =>
                import("../views/children/Settings/About/ShowAll.vue"),
            },
            {
              path: "add",
              name: "add_about",
              component: () =>
                import("../views/children/Settings/About/Add.vue"),
            },
            // Edit
            {
              path: "edit/:id",
              name: "edit_about",
              component: () =>
                import("../views/children/Settings/About/Edit.vue"),
              props: true,
            },
          ],
        },
        {
          alias: "/terms",
          path: "terms",
          component: () => import("../views/children/Settings/Terms/Home.vue"),
          children: [
            // Show
            {
              path: "show-all",
              name: "TermsShow",
              component: () =>
                import("../views/children/Settings/Terms/ShowAll.vue"),
            },
            {
              path: "add",
              name: "AddAbout",
              component: () =>
                import("../views/children/Settings/Terms/Add.vue"),
            },
            // Edit
            {
              path: "edit/:id",
              name: "EditAbout",
              component: () =>
                import("../views/children/Settings/Terms/Edit.vue"),
              props: true,
            },
          ],
        },
        {
          alias: "/policy",
          path: "policy",
          component: () => import("../views/children/Settings/Policy/Home.vue"),
          children: [
            // Show
            {
              path: "show-all",
              name: "PolicyShow",
              component: () =>
                import("../views/children/Settings/Policy/ShowAll.vue"),
            },
            {
              path: "add",
              name: "AddPolicy",
              component: () =>
                import("../views/children/Settings/Policy/Add.vue"),
            },
            // Edit
            {
              path: "edit/:id",
              name: "EditPolicy",
              component: () =>
                import("../views/children/Settings/Policy/Edit.vue"),
              props: true,
            },
          ],
        },
        {
          alias: "/vat",
          path: "vat",
          component: () => import("../views/children/Settings/Vat/Home.vue"),
          children: [
            // Show
            {
              path: "show-all",
              name: "VatShow",
              component: () =>
                import("../views/children/Settings/Vat/ShowAll.vue"),
            },
            {
              path: "add",
              name: "AddVat",
              component: () => import("../views/children/Settings/Vat/Add.vue"),
            },
            // Edit
            {
              path: "edit/:id",
              name: "EditVat",
              component: () =>
                import("../views/children/Settings/Vat/Edit.vue"),
              props: true,
            },
          ],
        },
        {
          alias: "/questions",
          path: "questions",
          component: () => import("../views/children/Settings/Faq/Home.vue"),
          children: [
            // Show
            {
              path: "show-all",
              name: "QuestionShow",
              component: () =>
                import("../views/children/Settings/Faq/ShowAll.vue"),
            },
            {
              path: "add",
              name: "AddQuestions",
              component: () => import("../views/children/Settings/Faq/Add.vue"),
            },
            // Edit
            {
              path: "edit/:id",
              name: "EditQuestions",
              component: () =>
                import("../views/children/Settings/Faq/Edit.vue"),
              props: true,
            },
          ],
        },
        {
          path: "/settings/global",
          name: "globalSettings",
          component: () =>
            import("../views/children/Settings/Global/index.vue"),
        },
        // ========== End Settings ==========
      ],
    },

    // Auth
    {
      path: "/login",
      name: "Login",
      component: Login,
    },

    // NotFound
    { path: "/:notFound(.*)", name: "NotFound", component: NotFound },
  ],
});

const TOKEN = store.getters["auth_module/currentUser"].token;

router.beforeEach((to, from, next) => {
  sessionStorage.setItem("from_route", from.fullPath);

  if (to.name !== "Login" && !TOKEN) {
    next({ name: "Login" });
  } else if (to.name == "Login" && TOKEN) {
    next("/");
  } else if (to.name == "Login" || to.name == "NotFound") {
    store.dispatch("sideNav_module/reseatBodyPadding");
    next();
  } else {
    next();
  }
});

Vue.use(VueRouter);
export default router;
