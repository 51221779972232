<template>
  <div class="introBg welcome-area">
    <!-- <div class="background-shape">
      <div
        class="circle1"
        data-aos="fade-left"
        data-aos-easing="ease-in-out"
        data-aos-once="true"
      ></div>
      <div
        class="circle2"
        data-aos="fade-left"
        data-aos-easing="ease-in-out"
        data-aos-once="true"
      ></div>
      <div
        class="circle3"
        data-aos="fade-left"
        data-aos-easing="ease-in-out"
        data-aos-once="true"
      ></div>
      <div
        class="circle4"
        data-aos="fade-left"
        data-aos-easing="ease-in-out"
        data-aos-once="true"
      ></div>
    </div> -->
    <div id="horizon">
      <div class="glow"></div>
    </div>
    <div class="all-text">
      <div id="title">
        <img :src="require('@/assets/images/icons/logo.png')" />
        Shams Online
      </div>
      <div id="subtitle" v-if="lang_global == 'ar'">
        <span> أهلا </span>
        <span> بك </span>
        <span> في لوحة تحكم شمس </span>
      </div>
      <div id="subtitle" v-if="lang_global == 'en'">
        <span> Welcome </span>
        <span> To </span>
        <span> Shams Dashboard </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {};
  },

  computed: {},

  methods: {},

  created() {},
};
</script>

<style lang="scss">
.introBg {
  width: 105%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  left: 50px;
  z-index: 2;
  background: radial-gradient(
    ellipse at bottom,
    var(--mainBgColor) 0%,
    var(--mainBgColor) 100%
  );
  bottom: -20px;
  top: 0;
  bottom: 0;
}

.all-text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 70vh;
}

#horizon {
  position: absolute;
  width: 160%;
  height: 60%;
  border-radius: 100%/100%;
  background: var(--mainColor-2);
  -webkit-filter: blur(120px);
  left: 50%;
  bottom: -40%;
  margin-left: -80%;
}

#horizon .glow {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 100%/100%;
  background: var(--mainColor-2);
  -webkit-filter: blur(100px);
  opacity: 0.7;
  top: -10%;
}

#title {
  font-weight: bold;
  font-size: 37px;
  text-align: center;
  letter-spacing: 0px;
  padding-left: 0px;
  background: -webkit-linear-gradient(
    var(--mainColor-2),
    var(--mainColor-2),
    var(--mainColor-2)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  animation-name: animGravity;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#title img {
  width: 200px;
}

#subtitle {
  font-weight: bold;
  font-size: 27px;
  text-align: center;
  letter-spacing: 1px;
}

#subtitle span {
  color: var(--mainColor-1);
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
}

#subtitle span:nth-child(1) {
  animation-name: animDont;
}

#subtitle span:nth-child(2) {
  animation-name: animLet;
}

#subtitle span:nth-child(3) {
  animation-name: animGo;
}

@keyframes animStar {
  from {
    transform: translateY(0px);
  }

  to {
    transform: translateY(-2000px);
  }
}

@keyframes animGravity {
  0% {
    transform: translateY(-25px);
    opacity: 0;
    letter-spacing: 0;
  }

  30%,
  80% {
    letter-spacing: 10px;
    padding-left: 10px;
    transform: translateY(0px);
    opacity: 1;
  }

  92%,
  100% {
    letter-spacing: 5px;
    padding-left: 5px;
    transform: translateY(-4px);
    opacity: 0;
  }
}

@keyframes animDont {
  0%,
  15% {
    transform: translateY(-26px);
    opacity: 0;
  }

  35%,
  80% {
    transform: translateY(0px);
    opacity: 1;
  }

  92%,
  100% {
    transform: translateY(-4px);
    opacity: 0;
  }
}

@keyframes animLet {
  0%,
  25% {
    transform: translateY(-26px);
    opacity: 0;
  }

  45%,
  80% {
    transform: translateY(0px);
    opacity: 1;
  }

  92%,
  100% {
    transform: translateY(-4px);
    opacity: 0;
  }
}

@keyframes animGo {
  0%,
  35% {
    transform: translateY(-26px);
    opacity: 0;
  }

  55%,
  80% {
    transform: translateY(0px);
    opacity: 1;
  }

  92%,
  100% {
    transform: translateY(-4px);
    opacity: 0;
  }
}

.welcome-area .background-shape .circle1 {
  width: 2200px;
  height: 2200px;
  border-radius: 50%;
  background: rgba(135, 19, 19, 0.05);
  position: absolute;
  z-index: -30;
  top: -1100px;
  right: -1100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .welcome-area .background-shape .circle1 {
    width: 1700px;
    height: 1700px;
    top: -850px;
    right: -850px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .welcome-area .background-shape .circle1 {
    width: 1700px;
    height: 1700px;
    top: -850px;
    right: -850px;
  }
}

@media only screen and (max-width: 767px) {
  .welcome-area .background-shape .circle1 {
    width: 1700px;
    height: 1700px;
    top: -850px;
    right: -850px;
  }
}

.welcome-area .background-shape .circle2 {
  width: 1700px;
  height: 1700px;
  border-radius: 50%;
  background: rgba(135, 19, 19, 0.05);
  position: absolute;
  z-index: -20;
  top: -850px;
  right: -850px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .welcome-area .background-shape .circle2 {
    width: 1200px;
    height: 1200px;
    top: -600px;
    right: -600px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .welcome-area .background-shape .circle2 {
    width: 1200px;
    height: 1200px;
    top: -600px;
    right: -600px;
  }
}

@media only screen and (max-width: 767px) {
  .welcome-area .background-shape .circle2 {
    width: 1200px;
    height: 1200px;
    top: -600px;
    right: -600px;
  }
}

.welcome-area .background-shape .circle3 {
  width: 1200px;
  height: 1200px;
  border-radius: 50%;
  background: rgba(135, 19, 19, 0.05);
  position: absolute;
  z-index: -10;
  top: -600px;
  right: -600px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .welcome-area .background-shape .circle3 {
    width: 700px;
    height: 700px;
    top: -350px;
    right: -350px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .welcome-area .background-shape .circle3 {
    width: 700px;
    height: 700px;
    top: -350px;
    right: -350px;
  }
}

@media only screen and (max-width: 767px) {
  .welcome-area .background-shape .circle3 {
    width: 700px;
    height: 700px;
    top: -350px;
    right: -350px;
  }
}

.welcome-area .background-shape .circle4 {
  width: 700px;
  height: 700px;
  border-radius: 50%;
  background: rgba(135, 19, 19, 0.05);
  position: absolute;
  z-index: -10;
  top: -350px;
  right: -350px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .welcome-area .background-shape .circle4 {
    width: 200px;
    height: 200px;
    top: -100px;
    right: -100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .welcome-area .background-shape .circle4 {
    width: 200px;
    height: 200px;
    top: -100px;
    right: -100px;
  }
}

@media only screen and (max-width: 767px) {
  .welcome-area .background-shape .circle4 {
    width: 200px;
    height: 200px;
    top: -100px;
    right: -100px;
  }
}

.welcome-area .hero-background-shape {
  position: absolute !important;
  top: -1px;
  right: -1px;
  z-index: -1;
}
</style>
